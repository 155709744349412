import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "flex-row" },
    [
      _vm.params.isAllocationPage && _vm.showCheckbox
        ? _c(
            "div",
            { staticClass: "checkbox-container" },
            [
              _c("vuex-checkbox", {
                attrs: {
                  getter: () => _vm.isSelected(),
                  setter: (value) => _vm.selectionHandler(value),
                  indeterminate:
                    _vm.indeterminate && !_vm.allSelectedInHierarchy,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasValue
        ? _c("promotion-rag-colour", {
            attrs: { colour: _vm.colour, "display-count": false },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isPromotion
        ? _c("div", { staticClass: "promotion-name" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("toSentenceCase")(_vm.params.data.description)) +
                "\n    "
            ),
          ])
        : _c(
            VTooltip,
            {
              attrs: { "z-index": "400", top: "", "max-width": 450 },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "div",
                        _vm._g(
                          _vm._b(
                            { staticClass: "promotion-name" },
                            "div",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm._f("toSentenceCase")(
                                  _vm.params.data.description
                                )
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm._f("toSentenceCase")(_vm.params.data.description))
                ),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }