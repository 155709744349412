import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "promotion-maintenance" }, [
    _c(
      "div",
      {
        staticClass: "promotion-maintenance__content",
        class: _vm.isParkingLot ? "" : ".non-parkinglot",
      },
      [
        _c(
          VForm,
          {
            ref: "promotion-maintenance-form",
            on: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
            model: {
              value: _vm.validation.isValid,
              callback: function ($$v) {
                _vm.$set(_vm.validation, "isValid", $$v)
              },
              expression: "validation.isValid",
            },
          },
          [
            _c(
              VTabs,
              {
                staticClass: "promo-tabs",
                attrs: {
                  value: _vm.selectedTab,
                  height: "2.5rem",
                  "background-color": "transparent",
                  "active-class": "promo-tabs__tab--active",
                  "hide-slider": "",
                },
                on: { change: _vm.navigateToTab },
              },
              [
                _vm._l(_vm.tabs, function (tab) {
                  return [
                    _c(
                      VTab,
                      {
                        key: "tab" + tab.tabName,
                        staticClass: "promo-tabs__tab",
                        attrs: { disabled: tab.disabled },
                      },
                      [
                        tab.disabled
                          ? _c(
                              VTooltip,
                              {
                                attrs: { "z-index": "400", top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "span",
                                            _vm._g(
                                              _vm._b({}, "span", attrs, false),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm._f("toSentenceCase")(
                                                      _vm.$t(tab.label)
                                                    )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(" "),
                                _vm.disabledTabTooltip
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            `planning.promotionsViewer.${_vm.disabledTabTooltip}`
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm._f("toSentenceCase")(_vm.$t(tab.label))
                                  ) +
                                  "\n                            "
                              ),
                              _vm.getTabState(tab) !== _vm.tabStates.invalid
                                ? _c(
                                    "div",
                                    [
                                      _vm.getTabState(tab) ===
                                        _vm.tabStates.notStarted ||
                                      _vm.getTabState(tab) ===
                                        _vm.tabStates.inProgress
                                        ? _c("icon", {
                                            attrs: {
                                              "icon-name":
                                                "empty-success-circle",
                                              small: "",
                                            },
                                          })
                                        : _vm.getTabState(tab) ===
                                          _vm.tabStates.completed
                                        ? _c("icon", {
                                            attrs: {
                                              "icon-name": "success-circle",
                                              small: "",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", {
                      key: "tab-spacer" + tab.tabName,
                      staticClass: "promo-tabs__spacer",
                    }),
                  ]
                }),
                _vm._v(" "),
                _c("div", { staticClass: "promo-tabs__tab-button-spacer" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "promo-tabs__tab-button" },
                  [
                    _vm.promotionForForecasting.splitPromotion
                      ? _c("split-promotion", {
                          attrs: {
                            "promotion-id": _vm.selectedPromotionId,
                            "product-count":
                              _vm.promotionForForecasting.products.length,
                            disabled: _vm.isSplitButtonDisabled,
                          },
                          on: {
                            "split-requested": function ($event) {
                              return _vm.$emit("split-requested")
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "promo-tabs__tab-button-spacer" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "promo-tabs__tab-button" },
                  [
                    !_vm.isParkingLotOrSubCampaignTab &&
                    _vm.scenario &&
                    _vm.scenario.isFavourite
                      ? _c("task-button", {
                          attrs: {
                            task: _vm.workflowTasks.submit,
                            "entity-type": _vm.workflowEntities.promotion,
                            "entity-ids": [_vm.selectedPromotionId],
                            "sub-campaign-id": _vm.scenario.subCampaignId,
                            "show-action-date-time": true,
                            categories:
                              _vm.promotion.userSelectedCategories || [],
                            "is-store-wide-promotion": _vm.isStoreWidePromotion,
                            disabled: _vm.isTaskButtonsDisabled,
                            "disabled-reason": _vm.actionsDisabledReason,
                            "short-text": "",
                          },
                          on: {
                            click: () => {
                              _vm.setIsActionDisabled(true)
                            },
                            "action-completed": () => {
                              _vm.setIsActionDisabled(false)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "promo-tabs__filler" }),
                _vm._v(" "),
                _vm.isPublishingModeBtnVisible
                  ? _c(
                      "div",
                      { staticClass: "promo-tabs__tab-button" },
                      [
                        _c(
                          "feature-toggle",
                          {
                            attrs: {
                              toggle: _vm.canUpdatePromotionPublishingMode,
                            },
                          },
                          [
                            _c(
                              "simple-button",
                              {
                                attrs: {
                                  disabled: _vm.isPublishingModeBtnDisabled,
                                },
                                on: { onClick: _vm.onPublishingModeBtnCLick },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.publishingModeBtnText) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isParkingLotOrSubCampaignTab &&
                _vm.scenario &&
                _vm.scenario.isFavourite
                  ? _c("task-buttons-dropdown", {
                      attrs: {
                        tasks: _vm.workflowTasksList,
                        "entity-type": _vm.workflowEntities.promotion,
                        "entity-ids": [_vm.selectedPromotionId],
                        "sub-campaign-id": _vm.scenario.subCampaignId,
                        "entity-instance": _vm.promotion,
                        categories: _vm.promotion.userSelectedCategories || [],
                        "is-store-wide-promotion": _vm.isStoreWidePromotion,
                        "is-disabled": _vm.isTaskButtonsDisabled,
                        "disabled-reason": _vm.actionsDisabledReason,
                      },
                      on: {
                        click: () => {
                          _vm.setIsActionDisabled(true)
                        },
                        "action-completed": () => {
                          _vm.setIsActionDisabled(false)
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.generalConfig.showWorkflowDebugIcon
                  ? _c(
                      "div",
                      { staticClass: "promo-tabs__notifications-icon" },
                      [
                        !_vm.isParkingLotOrSubCampaignTab
                          ? _c("workflow-state", {
                              attrs: {
                                "entity-id": _vm.selectedPromotionId,
                                "workflow-entity":
                                  _vm.workflowEntities.promotion,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "promo-tabs__notifications-icon" },
                  [
                    _c("notes", {
                      attrs: {
                        notes: _vm.notes,
                        disabled: !_vm.editContext,
                        "open-left": "",
                      },
                      on: {
                        delete: _vm.removeNote,
                        post: _vm.postNote,
                        save: _vm.saveNote,
                      },
                    }),
                  ],
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              VTabsItems,
              {
                model: {
                  value: _vm.selectedTab,
                  callback: function ($$v) {
                    _vm.selectedTab = $$v
                  },
                  expression: "selectedTab",
                },
              },
              _vm._l(_vm.tabs, function (tab, ix) {
                return _c(
                  VTabItem,
                  {
                    key: "tab-item-" + ix,
                    attrs: { transition: false, "reverse-transition": false },
                  },
                  [
                    _vm.isReadOnly && !_vm.pendingChangesToReview
                      ? _c("alert-banner", {
                          staticClass: "ml-4",
                          attrs: { reason: _vm.propagatedReadOnlyReason },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.pendingChangesToReview
                      ? _c("review-changes-dialog", {
                          attrs: {
                            "promotion-id": _vm.namespace,
                            "ignore-parent-read-only":
                              _vm.ignoreReadonlyForReviewChanges,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    tab.component
                      ? _c(
                          "div",
                          [
                            _c(tab.component, {
                              tag: "component",
                              attrs: {
                                context: _vm.customContext,
                                "form-ref": _vm.formRef,
                                "edit-mode": _vm.editMode,
                                namespace: _vm.namespace,
                                "is-new-promotion": _vm.isNewPromotion,
                                "is-sub-campaign-allocation":
                                  _vm.isSubCampaignAllocation,
                                "promotion-editing-state":
                                  _vm.promotionEditingState,
                                "is-parking-lot": _vm.isParkingLot,
                                "is-past-promotions": _vm.isPastPromotions,
                                "cache-dom": tab.cacheDOM,
                              },
                            }),
                          ],
                          1
                        )
                      : _c("h1", [_vm._v(_vm._s(tab.content))]),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(VDivider),
        _vm._v(" "),
        _c("div", { staticClass: "footer" }, [
          _c(
            "div",
            { staticClass: "footer__execution" },
            [
              _c(
                "feature-toggle",
                { attrs: { toggle: _vm.canManuallyValidatePromotion } },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "ma-1",
                      attrs: {
                        "ignore-parent-read-only": _vm.canValidate,
                        disabled: _vm.isTaskButtonsDisabled,
                        primary: "",
                        depressed: "",
                      },
                      on: { click: _vm.validatePromotion },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$t("execution.actions.validatePromotion")
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "feature-toggle",
                { attrs: { toggle: _vm.canManuallyExecutePromotion } },
                [
                  !_vm.isParkingLot && _vm.scenario && _vm.scenario.isFavourite
                    ? _c(
                        VBtn,
                        {
                          staticClass: "ma-1",
                          attrs: {
                            disabled: _vm.isTaskButtonsDisabled,
                            primary: "",
                            depressed: "",
                          },
                          on: { click: _vm.executePromotion },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("toSentenceCase")(
                                  _vm.$t("execution.actions.executePromotion")
                                )
                              ) +
                              "\n                    "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "footer__forecast-save-container" },
            [
              _c("unsaved-banner", {
                staticClass: "footer__unsaved",
                attrs: { small: "", "promotion-id": _vm.selectedPromotionId },
              }),
              _vm._v(" "),
              _vm.isPromotionSaveInProgress
                ? _c(
                    "div",
                    { staticClass: "footer__forecast-save-container__spinner" },
                    [_c("spinner-dynamic")],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isPromotionUnsaved
                ? _c(
                    "simple-button",
                    {
                      staticClass: "mr-2",
                      attrs: { "light-theme": "" },
                      on: { onClick: _vm.cancelChanges },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("toSentenceCase")(_vm.$t("actions.cancel"))
                          ) +
                          "\n                "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "positive-action-button",
                {
                  attrs: { disabled: _vm.isSavePromotionDisabled },
                  on: { onClick: _vm.forecastAndSavePromotion },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("toSentenceCase")(
                          _vm.$t("planning.buttons.forecastAndSavePromotion")
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "promotion-maintenance__sidebar" },
      [
        _c("promotion-forecasting", {
          attrs: {
            promotion: _vm.promotionForForecasting,
            "is-past-promotions": _vm.isPastPromotions,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }