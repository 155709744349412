'use strict';

import { size, find, isUndefined, isNil } from 'lodash';
import apportionFunctions from '@sharedModules/apportion-functions';
import calculateMarginFunctions from '@sharedModules/calculate-margin-functions';
import {
    isPromotionAssigned,
    resourcesPromotionIsAssignedToWorkflowStates,
} from '@sharedModules/promotion-utils';
import namespaces from '@enums/namespaces';
import workflowStates from '@enums/workflow-states';
import workflowEntities from '@enums/workflow-entities';
import notificationTypes from '@enums/notification-types';
import lockedReasons from '@enums/locked-reasons';
import validateDateTabFunctions from '../utils/promo-maintenance-area-validator-functions/validate-date-tab-functions';
import numberFormatterFunctions from '../utils/number-formatter';
import validateChannelsStoresTabFunctions from '../utils/promo-maintenance-area-validator-functions/validate-channels-stores-tab-functions';
import validateMechanicTabFunctions from '../utils/promo-maintenance-area-validator-functions/validate-mechanic-tab-functions';
import validateProductsTabFunctions from '../utils/promo-maintenance-area-validator-functions/validate-products-tab-functions';
import validateSupplierTabFunctions from '../utils/promo-maintenance-area-validator-functions/validate-supplier-tab-functions';
import supplierCommitmentsFilterFunctions from '../utils/supplier-commitments-filter-functions';
import firstWeekCalendarViewRestrictionFunctions from '../utils/weekly-calendar-view-restriction-functions/first-week-calendar-view-restriction-functions';
import lastWeekCalendarViewRestrictionFunctions from '../utils/weekly-calendar-view-restriction-functions/last-week-calendar-view-restriction-functions';
import promotionAllocationFilterFunctions from '../pages/preparation/components/allocation-areas/promotions/promotion-allocation-filter-functions';
import validateSupplyTabFunctions from '../utils/promo-maintenance-area-validator-functions/validate-supply-tab-functions';
import validateOfferTabFunctions from '../utils/promo-maintenance-area-validator-functions/validate-offer-tab-functions';

export default function createFeatureAwareFactory(featureDecisions) {
    /**
     * Create functions for handling feature toggling based on the loaded configuration.
     * See https://owlabs.atlassian.net/wiki/spaces/RTLS/pages/1100742755/Feature+Toggle+Approach
     */
    return {
        getApportionFunction() {
            const apportionFunctionToUse =
                apportionFunctions[featureDecisions.apportioningFunction];

            if (!apportionFunctionToUse) {
                throw new Error('Invalid apportioning function.');
            }

            return apportionFunctionToUse;
        },
        getCalculateMarginFunction() {
            const calculateMarginFunctionToUse =
                calculateMarginFunctions[featureDecisions.calculateMarginFunction];

            if (!calculateMarginFunctionToUse) {
                throw new Error('Invalid calculate margin function.');
            }

            return calculateMarginFunctionToUse;
        },
        getCalculateFundingFunctions(fundingFunction, type) {
            const calculateFundingFunctions =
                calculateMarginFunctions[
                    featureDecisions.calculateFundingFunctions[fundingFunction][type]
                ];

            if (!calculateFundingFunctions) {
                return null;
            }

            return calculateFundingFunctions;
        },
        getAllCalculateFundingFunctions() {
            return featureDecisions.calculateFundingFunctions;
        },
        isPromotionEditingDisabled(
            {
                promotionId,
                subCampaign,
                getPromotionById,
                entityWorkflowStateById,
                getParentPromotionsKeyedById,
                getPromotionNotifications,
            },
            moment
        ) {
            if (!isNil(promotionId) && promotionId !== namespaces.default) {
                const promotion = getPromotionById(promotionId);
                if (promotion.isGhost) {
                    return {
                        disabled: true,
                        reason: lockedReasons.ghostPromotion,
                    };
                }
                if (featureDecisions.disablePromotionEditingWhenAssignedToPromoResource) {
                    const promoIsAssigned = isPromotionAssigned({ promotionId, subCampaign });
                    if (promoIsAssigned) {
                        return {
                            disabled: true,
                            reason: lockedReasons.promotionAssignedToPromoResource,
                        };
                    }
                }
                if (
                    size(featureDecisions.disablePromotionEditingOnWorkflowStates) > 0 &&
                    !featureDecisions.canEditInFlightPromotion
                ) {
                    const promotionWorkflowState = entityWorkflowStateById({
                        id: promotionId,
                    });

                    const isDisabled = featureDecisions.disablePromotionEditingOnWorkflowStates.some(
                        workflowState =>
                            find(promotionWorkflowState, {
                                entity: workflowState.entity,
                                state: workflowState.state,
                                value: true,
                            })
                    );
                    if (
                        isDisabled &&
                        !(
                            promotion.clientState.toLowerCase() ===
                                workflowStates.approved.toLowerCase() &&
                            featureDecisions.canEditApprovedPromotion
                        )
                    ) {
                        return {
                            disabled: true,
                            reason: lockedReasons.promotionWorkflowState,
                        };
                    }
                }

                if (
                    size(featureDecisions.disablePromotionEditingOnResourceWorkflowStates) > 0 &&
                    !featureDecisions.canEditPromotionAssignedToSubmittedResource
                ) {
                    const resourcesWorkflowStates = resourcesPromotionIsAssignedToWorkflowStates(
                        subCampaign,
                        promotionId
                    );

                    const isDisabled = featureDecisions.disablePromotionEditingOnResourceWorkflowStates.some(
                        workflowState =>
                            find(resourcesWorkflowStates, {
                                entity: workflowState.entity,
                                state: workflowState.state,
                                value: true,
                            })
                    );

                    if (isDisabled) {
                        return {
                            disabled: true,
                            reason: lockedReasons.promotionSubCampaignWorkflowState,
                        };
                    }
                }

                if (
                    !isNil(featureDecisions.disablePromotionEditingDaysBeforeStart) &&
                    !featureDecisions.canEditInFlightPromotion
                ) {
                    const isPromoStartWithinRangeToDisable = this.isStartDateWithinXNumberOfDays(
                        {
                            startDate: promotion.startDate,
                            numberOfDays: featureDecisions.disablePromotionEditingDaysBeforeStart,
                        },
                        moment
                    );

                    if (isPromoStartWithinRangeToDisable) {
                        return {
                            disabled: true,
                            reason: lockedReasons.promotionPastStartDate,
                        };
                    }
                }

                if (featureDecisions.disablePromotionEditingWhenParentNotApproved) {
                    const parentPromotionId = promotion.parentPromotionId;
                    if (parentPromotionId) {
                        const parentPromotion =
                            getParentPromotionsKeyedById[parentPromotionId] || {};
                        const parentPromotionWorkflowState = parentPromotion.workflowState;
                        // hasBeenApproved means that promotion was once approved and even after it can be reject
                        // we need to keep child promotion editable
                        const hasBeenApproved = find(parentPromotionWorkflowState, {
                            entity: workflowEntities.promotion,
                            state: workflowStates.hasBeenApproved,
                            value: true,
                        });
                        if (hasBeenApproved) {
                            const notifications = getPromotionNotifications({
                                promotionId,
                                notificationKey: notificationTypes.parentPromotionUpdated,
                            });

                            if (notifications && notifications.length > 0) {
                                return {
                                    disabled: true,
                                    reason: lockedReasons.promotionNotificationExist,
                                };
                            }
                        }
                        if (isUndefined(hasBeenApproved)) {
                            return {
                                disabled: true,
                                reason: lockedReasons.promotionParentNotApproved,
                            };
                        }
                    }
                }
                if (
                    featureDecisions.disablePromotionEditingWhenPromotionNotInManualMode &&
                    promotion.parentPromotionId &&
                    !promotion.isInManualMode
                ) {
                    return {
                        disabled: true,
                        reason: lockedReasons.childPromotionNotInTheManualMode,
                    };
                }
                if (
                    featureDecisions.disablePromotionEditingWhenPromotionNotInManualMode &&
                    promotion.parentPromotionId &&
                    promotion.isInManualMode
                ) {
                    const notifications = getPromotionNotifications({
                        promotionId,
                        notificationKey: notificationTypes.parentPromotionUpdated,
                    });

                    if (notifications && notifications.length > 0) {
                        return {
                            disabled: true,
                            reason: lockedReasons.promotionNotificationExist,
                        };
                    }
                }
                // We should disable promotion when end date is in the past
                if (featureDecisions.canEditInFlightPromotion) {
                    const isPromotionEndDateInThePast = this.isEndDateInThePast(
                        {
                            endDate: promotion.endDate,
                        },
                        moment
                    );
                    if (isPromotionEndDateInThePast) {
                        return {
                            disabled: true,
                            reason: lockedReasons.promotionPastEndDate,
                        };
                    }
                }
            }

            return { disabled: false, reason: null };
        },
        isPromotionCreationDisabled({ scenario }, moment) {
            const isScenarioEndDateInThePast = this.isEndDateInThePast(
                {
                    endDate: scenario.endDate,
                },
                moment
            );
            return {
                disabled: isScenarioEndDateInThePast,
                reason: isScenarioEndDateInThePast ? lockedReasons.promotionPastEndDate : null,
            };
        },
        isPromoResourceEditingDisabled(
            { promoResourceId, subCampaignId, promoResourceWorkflowStateById, getSubCampaignById },
            moment
        ) {
            if (size(featureDecisions.disablePromoResourceEditingOnWorkflowStates) > 0) {
                const promoResourceWorkflowState = promoResourceWorkflowStateById({
                    id: promoResourceId,
                    subCampaignId,
                });

                const isDisabled = featureDecisions.disablePromoResourceEditingOnWorkflowStates.some(
                    workflowState =>
                        find(promoResourceWorkflowState, {
                            entity: workflowState.entity,
                            state: workflowState.state,
                            value: true,
                        })
                );

                if (isDisabled) {
                    return {
                        disabled: isDisabled,
                        reason: lockedReasons.promoResourcesWorkflowState,
                    };
                }
            }

            if (!isNil(featureDecisions.disablePromoResourceEditingDaysBeforeSubCampaignStart)) {
                const subCampaign = getSubCampaignById({
                    _id: subCampaignId,
                    usePluralResourceName: true,
                });

                const isSubCampaignStartWithinRangeToDisable = this.isStartDateWithinXNumberOfDays(
                    {
                        startDate: subCampaign.startDate,
                        numberOfDays:
                            featureDecisions.disablePromoResourceEditingDaysBeforeSubCampaignStart,
                    },
                    moment
                );

                return {
                    disabled: isSubCampaignStartWithinRangeToDisable,
                    reason: isSubCampaignStartWithinRangeToDisable
                        ? lockedReasons.promoResourcesPastStartDate
                        : null,
                };
            }

            return { disabled: false, reason: null };
        },
        isCampaignEditingDisabled({ campaign }, moment) {
            if (campaign && !isNil(featureDecisions.disableCampaignEditingDaysBeforeStart)) {
                // Do not check the start date, if the campaign does not exist. This could occur if a campaign is being deleted
                if (campaign) {
                    const isCampaignStartWithinRangeToDisable = this.isStartDateWithinXNumberOfDays(
                        {
                            startDate: campaign.startDate,
                            numberOfDays: featureDecisions.disableCampaignEditingDaysBeforeStart,
                        },
                        moment
                    );

                    return {
                        disabled: isCampaignStartWithinRangeToDisable,
                        reason: isCampaignStartWithinRangeToDisable
                            ? lockedReasons.campaignPastStartDate
                            : null,
                    };
                }
            }
            return { disabled: false, reason: null };
        },
        isSubCampaignEditingDisabled({ subCampaign }, moment) {
            if (subCampaign && !isNil(featureDecisions.disableSubCampaignEditingDaysBeforeStart)) {
                const isSubCampaignStartWithinRangeToDisable = this.isStartDateWithinXNumberOfDays(
                    {
                        startDate: subCampaign.startDate,
                        numberOfDays: featureDecisions.disableCampaignEditingDaysBeforeStart,
                    },
                    moment
                );

                return {
                    disabled: isSubCampaignStartWithinRangeToDisable,
                    reason: isSubCampaignStartWithinRangeToDisable
                        ? lockedReasons.subCampaignPastStartDate
                        : null,
                };
            }
            return { disabled: false, reason: null };
        },
        isScenarioEditingDisabled({ scenario }, moment) {
            if (
                !isNil(scenario) &&
                !isNil(featureDecisions.disableScenarioEditingDaysBeforeStart)
            ) {
                const isScenarioStartWithinRangeToDisable = this.isStartDateWithinXNumberOfDays(
                    {
                        startDate: scenario.startDate,
                        numberOfDays: featureDecisions.disableScenarioEditingDaysBeforeStart,
                    },
                    moment
                );

                return {
                    disabled: isScenarioStartWithinRangeToDisable,
                    reason: isScenarioStartWithinRangeToDisable
                        ? lockedReasons.scenarioPastStartDate
                        : null,
                };
            }
            return { disabled: false, reason: null };
        },
        getDateValidatorFunction() {
            const dateValidatorFunction =
                validateDateTabFunctions[featureDecisions.dateValidatorFunction];

            if (!dateValidatorFunction) {
                throw new Error('Invalid date validator function.');
            }

            return dateValidatorFunction;
        },
        getFormatNumberFunction() {
            const numberFilterFunction =
                numberFormatterFunctions[featureDecisions.numberFilterFunction];
            if (!numberFilterFunction) {
                throw new Error('Invalid date filter function.');
            }
            return numberFilterFunction;
        },
        getChannelsStoresValidatorFunction() {
            const channelsStoresValidatorFunction =
                validateChannelsStoresTabFunctions[
                    featureDecisions.channelsStoresValidatorFunction
                ];

            if (!channelsStoresValidatorFunction) {
                throw new Error('Invalid channels/stores validator function.');
            }

            return channelsStoresValidatorFunction;
        },
        getMechanicValidatorFunction() {
            const mechanicValidatorFunction =
                validateMechanicTabFunctions[featureDecisions.mechanicValidatorFunction];

            if (!mechanicValidatorFunction) {
                throw new Error('Invalid mechanic validator function.');
            }

            return mechanicValidatorFunction;
        },
        getProductsValidatorFunction() {
            const productsValidatorFunction =
                validateProductsTabFunctions[featureDecisions.productsValidatorFunction];

            if (!productsValidatorFunction) {
                throw new Error('Invalid products validator function.');
            }

            return productsValidatorFunction;
        },
        getSupplierValidatorFunction() {
            const supplierValidatorFunction =
                validateSupplierTabFunctions[featureDecisions.supplierValidatorFunction];

            if (!supplierValidatorFunction) {
                throw new Error('Invalid supplier validator function.');
            }

            return supplierValidatorFunction;
        },
        getSupplyValidatorFunction() {
            const supplyValidatorFunction =
                validateSupplyTabFunctions[featureDecisions.supplyValidatorFunction];

            if (!supplyValidatorFunction) {
                throw new Error('Invalid supply validator function.');
            }

            return supplyValidatorFunction;
        },
        getOfferValidatorFunction() {
            const offerValidatorFunction =
                validateOfferTabFunctions[featureDecisions.offerValidatorFunction];

            if (!offerValidatorFunction) {
                throw new Error('Invalid offer validator function.');
            }

            return offerValidatorFunction;
        },
        getNumberOfEffectivenessRatingColours() {
            const numberOfEffectivenessRatingColours =
                featureDecisions.numberOfEffectivenessRatingColours;

            if (!numberOfEffectivenessRatingColours) {
                throw new Error('Number of effectiveness rating colours is not configured.');
            }

            return numberOfEffectivenessRatingColours;
        },
        isStartDateWithinXNumberOfDays({ startDate, numberOfDays }, moment) {
            const momentStartDate = moment(startDate);
            const maxDate = moment()
                .add(numberOfDays, 'days')
                .startOf('isoDay');
            return maxDate.diff(momentStartDate, 'days') > 0;
        },
        isEndDateInThePast({ endDate }, moment) {
            return moment(endDate).isBefore(moment().startOf('day'));
        },
        getSupplierCommitmentsFilterFunction() {
            const supplierCommitmentsFilterFunction =
                supplierCommitmentsFilterFunctions[
                    featureDecisions.supplierCommitmentsFilterFunction
                ];

            if (!supplierCommitmentsFilterFunction) {
                throw new Error('Invalid supplier commitments filter function.');
            }

            return supplierCommitmentsFilterFunction;
        },
        getFirstWeekCalendarViewRestrictionFunction() {
            const firstWeekCalendarViewRestrictionFunction =
                firstWeekCalendarViewRestrictionFunctions[
                    featureDecisions.firstWeekCalendarViewRestriction
                ];

            if (!firstWeekCalendarViewRestrictionFunction) {
                throw new Error('Invalid first week calendar view restriction function.');
            }

            return firstWeekCalendarViewRestrictionFunction;
        },
        getLastWeekCalendarViewRestrictionFunction() {
            const lastWeekCalendarViewRestrictionFunction =
                lastWeekCalendarViewRestrictionFunctions[
                    featureDecisions.lastWeekCalendarViewRestriction
                ];

            if (!lastWeekCalendarViewRestrictionFunction) {
                throw new Error('Invalid last week calendar view restriction function.');
            }

            return lastWeekCalendarViewRestrictionFunction;
        },
        getPromotionStateIconsMap() {
            const promotionStateIconsMap = featureDecisions.stateIconsMap.promotion;

            if (!promotionStateIconsMap) {
                throw new Error('Promotion icons map is not configured.');
            }

            return promotionStateIconsMap;
        },
        getSubCampaignAllocationWorkflowStateIconsMap() {
            const iconsMap = featureDecisions.stateIconsMap.subCampaignAllocation;

            if (!iconsMap) {
                throw new Error('Sub-campaign allocation workflow icons map is not configured.');
            }

            return iconsMap;
        },
        getResourceStateIconsMap() {
            const resourceStateIconsMap = featureDecisions.stateIconsMap.resource;

            if (!resourceStateIconsMap) {
                throw new Error('Resource icons map is not configured.');
            }

            return resourceStateIconsMap;
        },
        getPromotionAllocationFilterFunction() {
            const promotionAllocationFilterFunction =
                promotionAllocationFilterFunctions[
                    featureDecisions.promotionAllocationFilterFunction
                ];

            if (!promotionAllocationFilterFunction) {
                throw new Error('Invalid promotion allocation filter function.');
            }

            return promotionAllocationFilterFunction;
        },
    };
}
