'use strict';

/**
 * Enum definition file for locked reasons.
 *
 * @module data/enums/reward-types
 */
module.exports = {
    promotionNotificationExist: 'planning.promotionsViewer.notificationExist',
    promotionAssignedToPromoResource:
        'planning.promotionsMaintenance.lockedMessages.assignedToPromoResource',
    promotionWorkflowState: 'planning.promotionsMaintenance.lockedMessages.workflowState',
    promotionSubCampaignWorkflowState:
        'planning.promotionsMaintenance.lockedMessages.subCampaignWorkflowState',
    promotionPastStartDate: 'planning.promotionsMaintenance.lockedMessages.pastStartDate',
    promotionPastEndDate: 'planning.promotionsMaintenance.lockedMessages.pastEndDate',
    promotionParentNotApproved: 'planning.promotionsViewer.parentNotApproved',
    childPromotionNotInTheManualMode: 'planning.promotionsViewer.notInManualMode',
    ghostPromotion: 'planning.promotionsMaintenance.lockedMessages.ghostPromotion',
    promoResourcesWorkflowState: 'preparation.promoResources.lockedMessages.workflowState',
    promoResourcesPastStartDate: 'preparation.promoResources.lockedMessages.pastStartDate',
    campaignPastStartDate: 'planning.editCampaign.lockedMessages.pastStartDate',
    subCampaignPastStartDate: 'planning.editSubCampaign.lockedMessages.pastStartDate',
    scenarioPastStartDate: 'planning.editScenario.lockedMessages.pastStartDate',
};
