import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "main-dialog",
        {
          ref: "dialog",
          attrs: {
            heading: _vm.labels.heading,
            "button-text": _vm.labels.btnText,
            "has-mandatory-fields": true,
            "can-save-changes-toggle": _vm.canSaveChanges,
            "btn-cancel-id": _vm.btnCancelId,
            "btn-submit-id": _vm.btnConfirmId,
            "has-activator": _vm.hasActivator,
            "confirm-btn-disabled": _vm.confirmBtnDisabled,
            validation: _vm.formValidationState,
            "override-save-btn-read-only": _vm.overrideSaveBtnReadOnly,
          },
          on: { confirm: _vm.handleConfirm, close: _vm.close, open: _vm.open },
          scopedSlots: _vm._u(
            [
              _vm.hasActivator
                ? {
                    key: "actions",
                    fn: function ({ activator }) {
                      return [
                        _c("resource-actions", {
                          attrs: {
                            "add-button": _vm.addBtn,
                            "edit-button": _vm.editMode,
                            "button-text": _vm.labels.btnText,
                            "has-border": _vm.labels.hasBorder,
                            activator: activator,
                            "ignore-parent-readonly": true,
                          },
                          on: { click: _vm.openDialog },
                        }),
                      ]
                    },
                  }
                : null,
              {
                key: "default",
                fn: function ({ validation }) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "campaign-container",
                        class: { "subcampaign-container": !_vm.isCampaign },
                      },
                      [
                        _vm.isDialogOpen
                          ? _c(
                              "vuex-form",
                              _vm._b(
                                {
                                  ref: "form",
                                  attrs: { validation: validation },
                                },
                                "vuex-form",
                                _vm.vBindObj,
                                false
                              )
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(VDivider),
                        _vm._v(" "),
                        _c(
                          VContainer,
                          {
                            staticClass: "campaign-container__bottom-container",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "author-container" },
                              [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("toSentenceCase")(
                                        _vm.$t("planning.createdBy")
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  VAvatar,
                                  {
                                    staticClass:
                                      "campaign-container__author-avatar",
                                    attrs: { size: "21" },
                                  },
                                  [
                                    _c("span", { staticClass: "white--text" }, [
                                      _vm._v(_vm._s(_vm.userName)),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(VDivider, { attrs: { vertical: "" } }),
                            _vm._v(" "),
                            _vm.editContext
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "campaign-container__last-updated",
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("toSentenceCase")(
                                            _vm.$t("planning.lastUpdated")
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "date" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateTime")(
                                            _vm.editContext.lastUpdatedOn
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "feature-toggle",
                              {
                                attrs: {
                                  toggle: _vm.canEditData,
                                  "slot-class":
                                    "campaign-container__bottom-container",
                                },
                              },
                              [
                                _c(
                                  "feature-toggle",
                                  { attrs: { toggle: _vm.canPublishEntity } },
                                  [
                                    _vm.hasPermissionToMakePrivate
                                      ? _c(
                                          "div",
                                          [
                                            _c(VDivider, {
                                              attrs: { vertical: "" },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              VBtn,
                                              {
                                                staticClass:
                                                  "campaign-container__publish",
                                                attrs: {
                                                  color: "primary",
                                                  disabled: _vm.isDisabled,
                                                  depressed: "",
                                                },
                                                on: {
                                                  click: _vm.togglePrivate,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm._f("toSentenceCase")(
                                                        _vm.$t(
                                                          _vm.privateEntityBtnText
                                                        )
                                                      )
                                                    ) +
                                                    "\n                                    "
                                                ),
                                                _c("icon", {
                                                  attrs: {
                                                    "icon-name": "publish",
                                                    right: "",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.editContext
                              ? _c(
                                  "feature-toggle",
                                  {
                                    attrs: {
                                      toggle: _vm.canEditData,
                                      "slot-class":
                                        "campaign-container__bottom-container",
                                    },
                                  },
                                  [
                                    _c(VDivider, {
                                      attrs: { vertical: "" },
                                    }),
                                    _vm._v(" "),
                                    _c(_vm.deleteDialog, {
                                      tag: "component",
                                      attrs: {
                                        resource: _vm.entityType,
                                        "resource-id": _vm.editContext._id,
                                        "edit-context": _vm.editContext,
                                      },
                                      on: {
                                        "resource-deleted": function ($event) {
                                          return _vm.close()
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isMakeManualBtnVisible
                              ? _c(
                                  "feature-toggle",
                                  {
                                    attrs: {
                                      toggle: _vm.canUpdatePublishingMode,
                                      "slot-class":
                                        "campaign-container__bottom-container-right",
                                    },
                                  },
                                  [
                                    _c(
                                      VTooltip,
                                      {
                                        attrs: { "z-index": "9999", top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "div",
                                                    _vm._g({}, on),
                                                    [
                                                      _c(
                                                        "simple-button",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              _vm.isMakeManualBtnDisabled,
                                                          },
                                                          on: {
                                                            onClick:
                                                              _vm.makeManual,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "planning.buttons.makeManual"
                                                                )
                                                              ) +
                                                              "\n                                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "planning.tooltips.makeManualTooltip"
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.editContext && _vm.isAllCampaignsView
                                      ? _c(VDivider, {
                                          attrs: { vertical: "" },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "feature-toggle",
                              {
                                attrs: {
                                  toggle: _vm.canEditData,
                                  "slot-class":
                                    "campaign-container__bottom-container-right",
                                },
                              },
                              [
                                _vm.isAddSubCampaignButtonVisible
                                  ? _c("sub-campaign-form-dialog", {
                                      attrs: {
                                        context: _vm.addSubCampaignFormContext,
                                        "add-btn": "",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.editContext && _vm.isAllCampaignsView
                                  ? _c(VDivider, { attrs: { vertical: "" } })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "feature-toggle",
                              { attrs: { toggle: _vm.canReadScenario } },
                              [
                                _vm.isViewScenariosButtonVisible
                                  ? _c(
                                      "simple-button",
                                      { on: { onClick: _vm.viewScenarios } },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm._f("toSentenceCase")(
                                                _vm.$t(
                                                  "planning.viewScenariosPromotions"
                                                )
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.isPlanOutCampaignButtonVisible
                              ? _c(
                                  VBtn,
                                  {
                                    attrs: { color: "black", depressed: "" },
                                    on: { click: _vm.planOutCampaign },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-weight-bold white--text",
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm._f("toSentenceCase")(
                                                _vm.$t(_vm.labels.planCampaign)
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            true
          ),
        },
        [
          _c(
            "template",
            { slot: "inheritance-badges" },
            [
              _c("parent-child-chips", {
                attrs: { context: _vm.editContext || {} },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm.hasConfirmationDialog
        ? _c("confirm-dialog", {
            ref: "confirmation-dialog",
            attrs: {
              "question-text": _vm.$t(_vm.dialogQuestionText),
              "action-text": _vm.$t(_vm.dialogActionText),
              "has-activator": false,
            },
            on: { confirm: _vm.confirmFunction },
            scopedSlots: _vm._u(
              [
                {
                  key: "warningContent",
                  fn: function () {
                    return [
                      _c(
                        _vm.dialogComponentName,
                        _vm._b(
                          { tag: "component" },
                          "component",
                          _vm.dialogProps,
                          false
                        )
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1002374754
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }