<template>
    <confirm-dialog
        ref="delete_dialog"
        :question-text="$t(labels.deleteHeading)"
        :warning-text="$t(labels.deleteWarning)"
        :action-text="$t(labels.deleteBtnText)"
        @confirm="deleteSelectedItem()"
    >
        <template v-slot:actions="{ activator }">
            <resource-actions
                :button-text="labels.deleteBtnText"
                :activator="activator"
                :resource-id="resourceId"
                :resource="resource"
                :delete-button-id="btnDeleteId"
                :ignore-parent-read-only="ignoreParentReadonly"
                :disabled="isDeleteDisabled"
                delete-button
            />
        </template>
        <template v-slot:warningContent>
            <div v-if="isDeleteDisabled" class="delete-description">
                {{ $t(labels.deleteDescription) | toSentenceCase }}
            </div>
            <child-dependencies-warning v-else-if="childDependenciesWarning" :resource="resource" />
            <direct-children-warning
                v-else-if="!isEmpty(children)"
                :number-of-children="children.length"
                :resource="resource"
            />
        </template>
    </confirm-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import UXEvents from '@enums/ux-events';
import {
    promotions,
    promotionProductGroups,
    supplierCommitments,
    rateCards,
    promotionRateCards,
    variableFundingAgreements,
    detailedProvisions,
    freeGifts,
    bookmarks,
    parkingLot,
    userProfiles,
    productOfferGroups,
} from '@enums/resources';
import { camelCase, isEmpty, get, cloneDeep, omit } from 'lodash';
import { removePromotionsFromInstances } from '@sharedModules/promotion-utils';
import { canDeletePromotion } from '@enums/feature-flags';
import navigation from '@/js/navigation';

const resourceLabelsAndDeleteActions = {
    [promotions]: {
        labels: {
            deleteBtnText: 'actions.delete',
            deleteHeading: 'dialogs.deletePromotion.header',
        },
        btnDeleteId: 'deletePromotionButton',
        permission: canDeletePromotion,
        async deleteSelectedItem() {
            // Check if the promotion has been assigned to a leaflet etc. when deleting
            const {
                pathsToUpdate,
                resourceDefinitions: updatedResourceDefinitions,
            } = removePromotionsFromInstances(cloneDeep(this.selectedSubCampaign), this.resourceId);

            const { error } = await this.deletePromotion({ id: this.resourceId });
            // if there are changes, make sure we set them in vuex so we can update the subcampaign
            if (!isEmpty(pathsToUpdate) && !error) {
                this.setUpdatedResourceDefinitions(updatedResourceDefinitions);
            }
            // redirect to scenario view if selected promotion was deleted
            if (this.resourceId === this.selectedPromotionId && !error) {
                navigation.toScenarioView({
                    scenarioId: this.selectedScenarioId,
                });
            }
            if (!error) {
                this.globalEmit(UXEvents.promotionDeleted);
            }
            this.closeAll();
        },
    },
    [rateCards]: {
        labels: {
            deleteBtnText: 'actions.delete',
            deleteHeading: 'dialogs.deleteRateCard.header',
        },
        btnDeleteId: 'deleteRateCard',
        deleteSelectedItem() {
            this.$emit('delete');
        },
    },
    [promotionRateCards]: {
        labels: {
            deleteBtnText: 'actions.delete',
            deleteHeading: 'dialogs.deletePromotionRateCard.header',
            deleteWarning: 'dialogs.deletePromotionRateCard.warning',
        },
        btnDeleteId: 'deleteRateCard',
        deleteSelectedItem() {
            this.$emit('delete');
        },
    },
    [freeGifts]: {
        labels: {
            deleteBtnText: 'actions.delete',
            deleteHeading: 'dialogs.deleteFreeGift.header',
        },
        btnDeleteId: 'deleteFreeGift',
        deleteSelectedItem() {
            this.$emit('delete');
        },
    },
    [productOfferGroups]: {
        labels: {
            deleteBtnText: 'actions.delete',
            deleteHeading: 'dialogs.deleteProductOfferGroup.header',
        },
        btnDeleteId: 'deleteProductOfferGroup',
        deleteSelectedItem() {
            this.$emit('delete');
        },
    },
    [variableFundingAgreements]: {
        labels: {
            deleteBtnText: 'actions.delete',
            deleteHeading: 'dialogs.variableFundingAgreement.header',
        },
        btnDeleteId: 'deleteVariableFundingAgreement',
        deleteSelectedItem() {
            this.$emit('delete');
        },
    },
    [camelCase(promotionProductGroups)]: {
        labels: {
            deleteBtnText: 'actions.delete',
            deleteHeading: 'dialogs.deleteProductGroup.header',
        },
        btnDeleteId: 'deleteProductGroup',
        deleteSelectedItem() {
            this.$emit('delete');
            this.closeAll();
        },
    },
    [camelCase(supplierCommitments)]: {
        labels: {
            deleteBtnText: 'actions.delete',
            deleteHeading: 'dialogs.deleteSupplierCommitment.header',
        },
        btnDeleteId: 'deleteSupplierCommitmentButton',
        async deleteSelectedItem() {
            await this.deleteSupplierCommitment({ id: this.resourceId });
            this.closeAll();
        },
    },
    [detailedProvisions]: {
        labels: {
            deleteBtnText: 'actions.delete',
            deleteHeading: 'dialogs.deleteDetailedProvision.header',
        },
        btnDeleteId: 'deleteDetailedProvision',
        deleteSelectedItem() {
            this.$emit('delete');
        },
    },
    [bookmarks]: {
        labels: {
            deleteBtnText: 'actions.delete',
            deleteHeading: 'dialogs.deleteBookmark.header',
        },
        btnDeleteId: 'deleteBookmark',
        deleteSelectedItem() {
            this.$emit('delete');
        },
    },
    [parkingLot]: {
        labels: {
            deleteBtnText: 'actions.delete',
            deleteHeading: 'dialogs.deletePromotion.header',
        },
        btnDeleteId: 'deletePromotionButton',
        async deleteSelectedItem() {
            let promo = null;
            // when we remove promotion from the subcampaign allocation
            // and this promo was on parking lot before, we need to update parking lot view
            // $emit('delete') doesn't work as element remove from the DOM and events from this element can't be catch in parent
            // for handle this case we can use global emit
            if (this.isDeleteFromSubCampaignAllocation) {
                promo = this.getPromotionById(this.resourceId);
            }
            const { error } = await this.deletePromotion({ id: this.resourceId });
            if (!error) {
                this.$emit('delete', this.resourceId);
                if (this.isDeleteFromSubCampaignAllocation && promo && promo.wasInParkingLot) {
                    this.globalEmit('allocate-promotions', [promo]);
                }
                this.closeAll();
            }
        },
    },
    [userProfiles]: {
        labels: {
            deleteBtnText: 'actions.delete',
            deleteHeading: 'dialogs.deleteUserProfile.header',
        },
        btnDeleteId: 'deleteUserProfileButton',
        async deleteSelectedItem() {
            const userProfile = this.getUserProfileById({
                _id: this.resourceId,
                usePluralResourceName: true,
            });

            // We soft delete the user profile by updating the 'deleted' flag,
            // and also by resetting the category and store-group level
            // permissions.
            this.updateUserProfile({
                _id: this.resourceId,
                updatedItem: omit(
                    {
                        ...userProfile,
                        deleted: true,
                        deletedDateTime: new Date(),
                        access: {
                            categories: [],
                            storeGroups: [],
                        },
                        accessRestrictions: {
                            storeGroupAttributeRestrictions: [],
                        },
                    },
                    ['isAdmin', 'rolesString']
                ),
            });

            this.closeAll();
        },
    },
};

export default {
    props: {
        resource: {
            type: String,
            required: true,
        },
        resourceId: {
            type: String,
            required: false,
        },
        editContext: {
            type: Object,
            required: false,
        },
        childDependenciesWarning: {
            type: Boolean,
            required: false,
            default: true,
        },
        children: {
            type: Array,
            default: () => [],
        },
        ignoreParentReadonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hasBeenApproved: {
            type: Boolean,
            default: false,
        },
        isDeleteFromSubCampaignAllocation: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapState('promotions', ['selectedPromotionId']),
        ...mapState('clientConfig', ['toggleLogic']),
        ...mapGetters('scenarios', ['selectedScenarioId']),
        ...mapGetters('subCampaigns', ['selectedSubCampaign']),
        ...mapGetters('userProfiles', ['getUserProfileById']),
        ...mapGetters('promotions', ['getPromotionById']),

        isDeleteDisabled() {
            return (
                this.disabled ||
                !get(
                    this.toggleLogic,
                    get(resourceLabelsAndDeleteActions, [this.resource, 'permission'], null),
                    true
                )
            );
        },

        labels() {
            return resourceLabelsAndDeleteActions[this.resource].labels;
        },

        btnDeleteId() {
            return resourceLabelsAndDeleteActions[this.resource].btnDeleteId;
        },
    },

    methods: {
        ...mapActions('promotions', ['deletePromotion']),
        ...mapActions('supplierCommitments', ['deleteSupplierCommitment']),
        ...mapActions('subCampaigns', ['setUpdatedResourceDefinitions']),
        ...mapActions('userProfiles', ['updateUserProfile']),

        isEmpty,

        close() {
            if (this.$refs.delete_dialog) {
                this.$refs.delete_dialog.close();
            }
        },

        closeAll() {
            this.close();
            // If delete-dialog was opened from common-form-dialog and delete was confirmed
            // we close both dialogs.
            this.$emit('resource-deleted');
        },

        async deleteSelectedItem() {
            await resourceLabelsAndDeleteActions[this.resource].deleteSelectedItem.call(this);
        },
    },
};
</script>

<style>
.delete-description {
    font-size: 1.4rem;
}
</style>
