import { merge } from 'lodash';
import to from 'await-to-js';
import axios from 'axios';
import storeMixin from '@/js/store/mixins/vuex-store';

const getInitialState = () => ({
    userProfiles: [],
    userNames: [],
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getUserProfileById
     */
    getters: {
        getUserNameById: state => id => {
            const user = state.userNames.find(userNameItem => userNameItem._id === id);
            return user ? `${user.firstname} ${user.surname}` : '';
        },
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setUserProfiles
     * - deleteUserProfiles
     * - updateUserProfiles
     * - addUserProfiles
     * - resetState
     */
    mutations: {
        setUserNames(state, userNames) {
            state.userNames = userNames;
        },
    },

    /**
     * Default actions available:
     * - fetchUserProfiles
     * - createUserProfiles
     * - deleteUserProfiles
     * - updateUserProfiles
     * - submitForm
     * - handleResponseNotifications
     * - resetState
     */
    actions: {
        async updateUserProfile({ commit, dispatch }, { _id, updatedItem = {} } = {}) {
            commit('setLoading', true);
            const mongoIdFields = [];

            const [err] = await to(
                axios.put(`/api/user-profiles/${_id}`, { updatedItem, mongoIdFields })
            );
            if (err) {
                commit('setLoading', false);
                throw new Error(err.message);
            }
            dispatch('fetchUserProfiles');
            dispatch('context/refreshUserContext', {}, { root: true });
        },
        async fetchUserNames({ commit }) {
            const [err, profileNames] = await to(axios.post(`/api/promotions/getUserNames`));

            if (err) {
                throw new Error(err.message);
            }
            commit('setUserNames', profileNames.data);
        },
    },
};

const mixinParams = {
    resource: 'user-profile',
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
