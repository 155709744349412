var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "ag-cell-label-container", class: `ag-sort-${_vm.sort}` },
      [
        _vm.params.enableMenu
          ? [
              _c(
                "div",
                {
                  ref: "menuButton",
                  staticClass: "custom-header-menu-button",
                  on: {
                    click: function ($event) {
                      return _vm.onMenuClicked($event)
                    },
                  },
                },
                [
                  _c("span", {
                    staticClass: "ag-icon ag-icon-menu",
                    attrs: { role: "presentation" },
                  }),
                ]
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        !_vm.params.unsortable
          ? _c(
              "span",
              {
                staticClass: "ag-header-icon ag-header-label-icon",
                class: `ag-sort-${_vm.sort}-icon`,
                attrs: { "aria-hidden": "true" },
                on: { click: _vm.toggleSort },
              },
              [
                _c("span", {
                  class: `ag-icon ag-icon-${_vm.sortShort}`,
                  attrs: { role: "presentation" },
                }),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.params.iconComponent
          ? _c(_vm.params.iconComponent, { tag: "component" })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }