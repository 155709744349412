var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("confirm-dialog", {
    ref: "delete_dialog",
    attrs: {
      "question-text": _vm.$t(_vm.labels.deleteHeading),
      "warning-text": _vm.$t(_vm.labels.deleteWarning),
      "action-text": _vm.$t(_vm.labels.deleteBtnText),
    },
    on: {
      confirm: function ($event) {
        return _vm.deleteSelectedItem()
      },
    },
    scopedSlots: _vm._u([
      {
        key: "actions",
        fn: function ({ activator }) {
          return [
            _c("resource-actions", {
              attrs: {
                "button-text": _vm.labels.deleteBtnText,
                activator: activator,
                "resource-id": _vm.resourceId,
                resource: _vm.resource,
                "delete-button-id": _vm.btnDeleteId,
                "ignore-parent-read-only": _vm.ignoreParentReadonly,
                disabled: _vm.isDeleteDisabled,
                "delete-button": "",
              },
            }),
          ]
        },
      },
      {
        key: "warningContent",
        fn: function () {
          return [
            _vm.isDeleteDisabled
              ? _c("div", { staticClass: "delete-description" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm._f("toSentenceCase")(
                          _vm.$t(_vm.labels.deleteDescription)
                        )
                      ) +
                      "\n        "
                  ),
                ])
              : _vm.childDependenciesWarning
              ? _c("child-dependencies-warning", {
                  attrs: { resource: _vm.resource },
                })
              : !_vm.isEmpty(_vm.children)
              ? _c("direct-children-warning", {
                  attrs: {
                    "number-of-children": _vm.children.length,
                    resource: _vm.resource,
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }