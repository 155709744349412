<template>
    <div>
        <main-dialog
            ref="dialog"
            :heading="labels.heading"
            :button-text="labels.btnText"
            :has-mandatory-fields="true"
            :can-save-changes-toggle="canSaveChanges"
            :btn-cancel-id="btnCancelId"
            :btn-submit-id="btnConfirmId"
            :has-activator="hasActivator"
            :confirm-btn-disabled="confirmBtnDisabled"
            :validation="formValidationState"
            :override-save-btn-read-only="overrideSaveBtnReadOnly"
            @confirm="handleConfirm"
            @close="close"
            @open="open"
        >
            <template slot="inheritance-badges">
                <parent-child-chips :context="editContext || {}" />
            </template>
            <template v-if="hasActivator" v-slot:actions="{ activator }">
                <resource-actions
                    :add-button="addBtn"
                    :edit-button="editMode"
                    :button-text="labels.btnText"
                    :has-border="labels.hasBorder"
                    :activator="activator"
                    :ignore-parent-readonly="true"
                    @click="openDialog"
                />
            </template>
            <template v-slot:default="{ validation }">
                <div class="campaign-container" :class="{ 'subcampaign-container': !isCampaign }">
                    <!--Form-->
                    <vuex-form
                        v-if="isDialogOpen"
                        ref="form"
                        v-bind="vBindObj"
                        :validation="validation"
                    />

                    <v-divider />

                    <v-container class="campaign-container__bottom-container">
                        <!--Created By-->
                        <div class="author-container">
                            <p>{{ $t('planning.createdBy') | toSentenceCase }}</p>
                            <v-avatar class="campaign-container__author-avatar" size="21">
                                <span class="white--text">{{ userName }}</span>
                            </v-avatar>
                        </div>

                        <v-divider vertical />

                        <!--Last updated-->
                        <div v-if="editContext" class="campaign-container__last-updated">
                            <p>{{ $t('planning.lastUpdated') | toSentenceCase }}</p>
                            <span class="date">{{ editContext.lastUpdatedOn | dateTime }}</span>
                        </div>

                        <!--Publish button-->
                        <feature-toggle
                            :toggle="canEditData"
                            slot-class="campaign-container__bottom-container"
                        >
                            <feature-toggle :toggle="canPublishEntity">
                                <div v-if="hasPermissionToMakePrivate">
                                    <v-divider vertical />

                                    <v-btn
                                        class="campaign-container__publish"
                                        color="primary"
                                        :disabled="isDisabled"
                                        depressed
                                        @click="togglePrivate"
                                    >
                                        {{ $t(privateEntityBtnText) | toSentenceCase }}
                                        <icon icon-name="publish" right />
                                    </v-btn>
                                </div>
                            </feature-toggle>
                        </feature-toggle>

                        <!--Delete button-->
                        <feature-toggle
                            v-if="editContext"
                            :toggle="canEditData"
                            slot-class="campaign-container__bottom-container"
                        >
                            <v-divider vertical />
                            <component
                                :is="deleteDialog"
                                :resource="entityType"
                                :resource-id="editContext._id"
                                :edit-context="editContext"
                                @resource-deleted="close()"
                            />
                        </feature-toggle>
                        <!--Make manual button-->
                        <feature-toggle
                            v-if="isMakeManualBtnVisible"
                            :toggle="canUpdatePublishingMode"
                            slot-class="campaign-container__bottom-container-right"
                        >
                            <v-tooltip z-index="9999" top>
                                <template v-slot:activator="{ on }">
                                    <div v-on="on">
                                        <simple-button
                                            :disabled="isMakeManualBtnDisabled"
                                            @onClick="makeManual"
                                        >
                                            {{ $t('planning.buttons.makeManual') }}
                                        </simple-button>
                                    </div>
                                </template>
                                <span>
                                    {{ $t('planning.tooltips.makeManualTooltip') }}
                                </span>
                            </v-tooltip>
                            <v-divider v-if="editContext && isAllCampaignsView" vertical />
                        </feature-toggle>

                        <!--Add sub-campaign button-->
                        <feature-toggle
                            :toggle="canEditData"
                            slot-class="campaign-container__bottom-container-right"
                        >
                            <sub-campaign-form-dialog
                                v-if="isAddSubCampaignButtonVisible"
                                :context="addSubCampaignFormContext"
                                add-btn
                            />
                            <v-divider v-if="editContext && isAllCampaignsView" vertical />
                        </feature-toggle>

                        <!--View scenarios/promotions button-->
                        <feature-toggle :toggle="canReadScenario">
                            <simple-button
                                v-if="isViewScenariosButtonVisible"
                                @onClick="viewScenarios"
                            >
                                {{ $t('planning.viewScenariosPromotions') | toSentenceCase }}
                            </simple-button>
                        </feature-toggle>

                        <!--Plan-out campaign button-->
                        <v-btn
                            v-if="isPlanOutCampaignButtonVisible"
                            color="black"
                            depressed
                            @click="planOutCampaign"
                        >
                            <span class="font-weight-bold white--text">
                                {{ $t(labels.planCampaign) | toSentenceCase }}
                            </span>
                        </v-btn>
                    </v-container>
                </div>
            </template>
        </main-dialog>
        <confirm-dialog
            v-if="hasConfirmationDialog"
            ref="confirmation-dialog"
            :question-text="$t(dialogQuestionText)"
            :action-text="$t(dialogActionText)"
            :has-activator="false"
            @confirm="confirmFunction"
        >
            <template v-slot:warningContent>
                <component :is="dialogComponentName" v-bind="dialogProps" />
            </template>
        </confirm-dialog>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import { camelCase, isFunction, get, isNil } from 'lodash';
import { campaigns, subCampaigns, scenarios } from '@enums/resources';
import {
    canEditCampaign,
    canEditSubCampaign,
    canReadScenario,
    canCreateScenario,
    canEditScenario,
    canEditChannel,
    noRestriction,
    canPublishCampaigns,
    canPublishSubCampaigns,
    canPublishScenarios,
    canViewScenariosFrontEnd,
    canUpdatePromotionPublishingMode,
} from '@enums/feature-flags';
import namespaces from '@enums/namespaces';
import tabNames from '@enums/tab-names';
import vuexFormDialogMixin from '../mixins/vuex-form-dialog';
import { constructVBindObj } from '@/js/utils/component-utils';
import navigation from '@/js/navigation';

export default {
    mixins: [vuexFormDialogMixin],

    props: {
        hasActivator: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            canReadScenario,
            isDialogCreated: false,
            confirmBtnDisabled: false,
            isDialogOpen: false,
            isMakeManualBtnDisabled: false,
        };
    },

    computed: {
        ...mapGetters('campaigns', ['isCampaignSelected']),
        ...mapState('clientConfig', ['toggleLogic']),
        ...mapState('context', ['profile']),
        namespace() {
            return this.editContext ? this.editContext._id : namespaces.default;
        },
        formValidationState() {
            return this.$store.state[this.entityType].validationStates[this.namespace] || {};
        },
        // If end date in the future for compaign, sub-campaign and scenario in future we need
        // allow to edit endDate and make save button active
        overrideSaveBtnReadOnly() {
            if (this.$props && this.$props.editContext && this.$props.editContext.endDate) {
                const { endDate } = this.$props.editContext;
                const isEndDateInFuture = this.$moment(endDate).isAfter(
                    this.$moment().startOf('day')
                );
                return isEndDateInFuture;
            }

            return false;
        },
        vBindObj() {
            return {
                ...this.form,
                ...this.$props,
            };
        },
        canSaveChanges() {
            switch (this.resource) {
                case campaigns:
                    return canEditCampaign;
                case subCampaigns:
                    // If the user doesn't have explicit permission to edit
                    // a sub-campaign, check to see if they have the
                    // canEditChannel permission, as this will enable the
                    // user to update promo resources.
                    return this.toggleLogic[canEditSubCampaign]
                        ? canEditSubCampaign
                        : canEditChannel;
                case scenarios:
                    return canCreateScenario || canEditScenario;
                default:
                    return noRestriction;
            }
        },
        canEditData() {
            switch (this.resource) {
                case campaigns:
                    return canEditCampaign;
                case subCampaigns:
                    return canEditSubCampaign;
                case scenarios:
                    return canEditScenario;
                default:
                    return noRestriction;
            }
        },
        canUpdatePublishingMode() {
            return canUpdatePromotionPublishingMode;
        },
        addSubCampaignFormContext() {
            return this.isCampaign ? this.editContext : this.context;
        },
        entityType() {
            return camelCase(this.resource);
        },
        isCampaign() {
            return this.resource === campaigns;
        },
        isSubCampaign() {
            return this.resource === subCampaigns;
        },
        isScenario() {
            return this.resource === scenarios;
        },
        deleteDialog() {
            if (this.isScenario) return 'scenario-delete-dialog';
            if (this.isSubCampaign) return 'sub-campaign-delete-dialog';
            if (this.isCampaign) return 'campaign-delete-dialog';
            return 'common-delete-dialog';
        },
        privateEntityBtnText() {
            const isPrivate = this.getIsPrivatePropertyFromStagingArea();
            return isPrivate ? 'planning.publish' : 'planning.hide';
        },
        hasPermissionToMakePrivate() {
            // A scenario that has been marked as the favourite cannot be made private.
            const isFavourite = this.isScenario && this.editContext && this.editContext.isFavourite;
            return this.editContext && !isFavourite;
        },
        canPublishEntity() {
            switch (this.resource) {
                case campaigns:
                    return canPublishCampaigns;
                case subCampaigns:
                    return canPublishSubCampaigns;
                case scenarios:
                    return canPublishScenarios;
                default:
                    return false;
            }
        },

        isAllCampaignsView() {
            return !this.isCampaignSelected;
        },

        isAddSubCampaignButtonVisible() {
            return (
                this.editContext &&
                ((this.isAllCampaignsView && !this.isScenario) || this.isCampaign)
            );
        },

        isViewScenariosButtonVisible() {
            return (
                this.editContext &&
                !this.isAllCampaignsView &&
                this.isSubCampaign &&
                // It's possible for users to have the canReadScenario permission
                // for the purpose of enabling specific functionality, without
                // actually being able to view the scenarios themselves.
                // For example, the channel creator role is able to edit a
                // sub-campaign, but not allowed to view scenarios. We have logic
                // in place to disable sub-campaign attributes depending on what
                // values have been set in the associated scenarios. This means
                // that the channel creater role needs to have the canReadScenario
                // permission in order to correctly update a sub-campaign, despite
                // not being allowed to view the scenarios.
                this.toggleLogic[canViewScenariosFrontEnd]
            );
        },

        isPlanOutCampaignButtonVisible() {
            return this.editContext && this.isAllCampaignsView && !this.isScenario;
        },
        userName() {
            const userNameSource =
                this.editMode && this.editContext && this.editContext.user
                    ? this.editContext.user
                    : this.profile;

            if (userNameSource) {
                const { firstname, surname } = userNameSource;
                return this.$options.filters.toSentenceCase(`${firstname[0]}${surname[0]}`);
            }

            return '';
        },
        isDisabled() {
            return this.isReadOnly;
        },
        confirmFunction() {
            return isFunction(this.dialogConfirmFunction)
                ? this.dialogConfirmFunction
                : this.saveEntity;
        },
        isMakeManualBtnVisible() {
            return this.isSubCampaign && this.editContext && this.editContext.parentId;
        },
    },

    watch: {
        isDialogOpen(value) {
            // Focus on first input field
            // Timeout needed because form is not available right away
            setTimeout(() => {
                if (value && isNil(this.editContext)) {
                    if (get(this.$refs.form, '$refs.form.inputs')) {
                        this.$refs.form.$refs.form.inputs[0].focus();
                    }
                }
            }, 200);
        },
    },

    methods: {
        ...mapMutations('campaigns', ['setCampaignIsPrivateAttribute']),
        ...mapMutations('subCampaigns', ['setSubCampaignIsPrivateAttribute']),
        ...mapMutations('scenarios', ['setScenarioIsPrivateAttribute']),
        ...mapActions('promotions', ['updatePromoPublishingModeForSubCampaign']),

        async makeManual() {
            this.isMakeManualBtnDisabled = true;
            await this.updatePromoPublishingModeForSubCampaign({
                subCampaignId: this.editContext._id,
            });
            this.isMakeManualBtnDisabled = false;
        },

        populateFields() {
            // Need to do use nextTick because form is not available yet
            this.$nextTick(() => {
                if (this.$refs.form) {
                    // Ensure namespace exists in the staging area if editing.
                    if (this.editContext) {
                        this.$store.dispatch(
                            `${this.vBindObj.vuexModule}/createStagingAreaNamespace`,
                            {
                                namespace: this.editContext._id,
                            }
                        );
                    }

                    this.$refs.form.populateFieldsInStagingArea();
                }
            });
        },
        selectCampaignSingleView() {
            const itemId = this.editContext.campaignId || this.editContext._id;
            navigation.toCampaignView({
                tabName: this.preparationView ? tabNames.preparation : tabNames.planning,
                campaignId: itemId,
            });

            this.close();
        },
        togglePrivate() {
            const isPrivate = !this.getIsPrivatePropertyFromStagingArea();
            const id = this.editContext._id;

            switch (this.resource) {
                case campaigns:
                    this.setCampaignIsPrivateAttribute({ id, isPrivate });
                    break;
                case subCampaigns:
                    this.setSubCampaignIsPrivateAttribute({ id, isPrivate });
                    break;
                case scenarios:
                    this.setScenarioIsPrivateAttribute({ id, isPrivate });
                    break;
                default:
                    break;
            }
        },
        getIsPrivatePropertyFromStagingArea() {
            const stagingArea =
                this.editContext &&
                this.$store.state[this.entityType].stagingArea[this.editContext._id];
            return stagingArea && stagingArea.isPrivate;
        },

        openDialog() {
            this.isDialogOpen = true;
            this.$refs.dialog.open();
            this.populateFields();
        },
        close() {
            this.setModalExpansionPanelsState();
            if (this.$refs.dialog) {
                this.$refs.dialog.close();
            }
            this.$emit('close');
            this.isDialogOpen = false;
        },

        open() {
            // use nextTick because form is not available yet
            this.$nextTick(() => {
                if (!this.isDialogCreated) {
                    this.setModalExpansionPanelsState();
                    this.isDialogCreated = true;
                }
                // ensuring confirm button is not locked by default when dialog opens
                this.confirmBtnDisabled = false;
            });
        },

        handleConfirm() {
            // disable save button after pushing so it can be pushed only once
            this.confirmBtnDisabled = true;
            // By default there is no confirmation modal and we can simply save
            // the entity. If a specific confirmation modal needs to be displayed,
            // then we can overwrite this function in the entity's form-dialog component.
            this.saveEntity();
        },

        async saveEntity() {
            // disable save button after pushing so it can be pushed only once
            this.confirmBtnDisabled = true;
            if (this.$refs['confirmation-dialog']) {
                this.$refs['confirmation-dialog'].close();
            }
            const isSaveSuccessful = await this.save();
            if (!isSaveSuccessful) {
                // re-enable save button if save fails
                this.confirmBtnDisabled = false;
            }
        },

        setExpansionPanelState(isOpenState, fieldsArray) {
            let additionalFieldsHaveDefaultValues = true;
            if (this.$refs.form) {
                additionalFieldsHaveDefaultValues = this.$refs.form.checkAllFieldsHaveDefaultValues(
                    fieldsArray
                );
            }
            this[isOpenState] = additionalFieldsHaveDefaultValues ? null : 0;
        },

        onExpansionPanelStateChange(isOpenState, value) {
            // 0 represents the open state as vuetify expansion-panels component uses
            // an index to render the currently opened panel
            // undefined value is not recognized as changed one
            this[isOpenState] = value === undefined ? null : 0;
        },

        // Abstract method declaration. Could be implemented at child level
        // for specifying modal expansion panels state changing logic.
        setModalExpansionPanelsState() {},

        constructVBindObj,
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.campaign-container {
    color: $promo-text-colour;
    max-width: 83rem;

    &::v-deep .vuex-form {
        display: grid;
        grid-template:
            'title description dates'
            'link description workflow'
            'details details details'
            'store-groups store-groups store-groups'
            'nominations nominations nominations '
            'tags tags tags'
            'children children children'
            'rate-cards rate-cards rate-cards'
            / 1fr 1fr 1fr;

        &__dates {
            grid-area: dates;
        }

        &__link {
            grid-area: link;
        }

        &__workflow {
            grid-area: workflow;
        }

        &__details {
            grid-area: details;
            border-top: $component-border-divider;
            margin: 0 !important;

            .details {
                &__wrapper {
                    display: grid;
                    grid-template:
                        'categories . . regions'
                        'promo-resources custom-restrictions . .';
                }
            }
        }

        &__nominations {
            grid-area: nominations;
            border-top: $component-border-divider;
            margin: 0 !important;
            padding-bottom: 1rem;
        }

        &__tags {
            grid-area: tags;
            border-top: $component-border-divider;
            margin: 0 !important;
            padding-bottom: 1rem;
        }

        &__children {
            grid-area: children;
            border-top: $component-border-divider;
            margin: 0 !important;
            padding-bottom: 1rem;
        }

        &__rate-cards {
            grid-area: rate-cards;
            border-top: $component-border-divider;
            margin: 0 !important;
            padding-bottom: 1rem;
        }

        &__name {
            grid-area: title;
            padding-right: 2rem;
            width: 26.5rem;
        }

        &__briefing {
            grid-area: description;
            padding-right: 2rem;
            width: 38.5rem;
        }

        .rtls-expansion-panel-content {
            .v-expansion-panel-content__wrap {
                display: contents;
            }
        }

        &__store-groups {
            grid-area: store-groups;
            border-top: $component-border-divider;
            margin: 0 !important;
            padding-bottom: 1rem;

            &-selector {
                display: flex;
                min-width: 50%;
                max-width: 100%;
            }
        }

        &__promo-resources {
            grid-area: promo-resources;
            padding-bottom: 1.4rem;

            .icon-checkboxes-list-container__control {
                padding: 0;
                display: flex;
                position: relative;
                border-right: $component-border-divider;

                .v-input--checkbox.error--text {
                    padding-bottom: 1rem;
                }

                .v-messages.error--text {
                    position: absolute;
                    top: 2.5rem;
                    left: 0;
                }
            }
        }

        &__categories {
            grid-area: categories;
            grid-column: 1 / span 2;
        }

        &__customer-restrictions {
            grid-area: custom-restrictions;
            padding: 0;

            .checkboxes-list-container__control {
                display: flex;
                position: relative;

                .v-messages.error--text {
                    position: absolute;
                    top: 2rem;
                    left: 0;
                }

                .rtls-checkbox {
                    margin-right: 1.5rem;
                }
            }
        }
    }

    &__bottom-container {
        display: flex;
        align-items: center;

        .author-container {
            display: flex;
            justify-content: space-between;
            line-height: 2.1rem;
            font-size: 1.2rem;
            font-weight: 600;

            & > p {
                margin-right: 1.3rem;
            }
        }
        .v-divider {
            margin-right: 1.3rem;
            margin-left: 1.3rem;
        }

        &-right {
            display: flex;
            margin-left: auto;
        }
    }

    &__author-avatar {
        background-color: $promo-text-colour;
        font-size: 1rem;
        font-weight: 400;
    }

    &__last-updated {
        font-size: 1.2rem;
        font-weight: 600;
        .date {
            font-weight: 400;
        }
    }
}

.subcampaign-container {
    max-width: 90rem;
    &::v-deep .vuex-form {
        .rtls-expansion-panel-content {
            padding-top: 1.5rem;
        }
    }
}
</style>
