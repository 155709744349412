'use strict';

const jp = require('jsonpath');
const {
    compact,
    cloneDeep,
    round,
    get,
    keyBy,
    isNil,
    isEmpty,
    isNumber,
    set,
    last,
    difference,
    pick,
    forEach,
    find,
    reduce,
    remove,
    includes,
    uniq,
    map,
    some,
    union,
} = require('lodash');
const parkingLotStates = require('./data/enums/parking-lot-states');
const pogScope = require('./data/enums/product-offer-group-scope');
const { apportionMappings } = require('./funding-utils');

function assignedPromotionsIds(subCampaign) {
    const promotionsIds = jp.query(
        subCampaign,
        '$.resourceDefinitions[*].pages[*].assignment..promotionId'
    );

    return promotionsIds ? compact(promotionsIds) : [];
}

function isPromotionAssigned({ promotionId, subCampaign }) {
    const assignedPromotions = assignedPromotionsIds(subCampaign);

    return !!assignedPromotions.find(id => String(id) === promotionId);
}

function generateRemovalPath(path, isHeroProducts = false) {
    let slicedPath;
    if (isHeroProducts) {
        slicedPath = path.slice(1, -1);
    } else {
        slicedPath = path.slice(1);
    }
    const removalPath = slicedPath.reduce((newPath, item) => {
        // if we see a number we need to add square brackets around
        // it so that we can use it when using lodash set
        // e.g. 0 => [0]
        if (isNumber(item)) {
            newPath.push(`[${item}]`);
        } else {
            newPath.push(item);
        }
        return newPath;
    }, []);
    return removalPath;
}

function removeProductFromSubCampaignHeroProducts(subCampaign, productKeys) {
    // Find and remove products from a leaflet etc at the subcampaign level
    if (isEmpty(subCampaign)) return { hasUpdates: false, resourceDefinitions: null };
    const allPaths = [];
    productKeys.forEach(productKey => {
        const productPaths = jp.paths(
            subCampaign,
            `$.resourceDefinitions[*].pages[*].assignment[*].products[?(@ == ${productKey})]`
        );
        allPaths.push(...productPaths);
    });
    let hasUpdates = false;
    allPaths.forEach(path => {
        const removalPath = generateRemovalPath(path, true);
        const currentValue = cloneDeep(get(subCampaign, path.slice(1, -1)));
        const productsArray = get(subCampaign, path.slice(1, -1));
        // last(path) returns the index of the product so we splice it out
        productsArray.splice(last(path), 1);
        if (!isEmpty(difference(currentValue, productsArray))) {
            hasUpdates = true;
            set(subCampaign, removalPath.join('.'), productsArray);
        }
    });
    return { hasUpdates, resourceDefinitions: subCampaign.resourceDefinitions };
}

function resourcesPromotionIsAssignedToWorkflowStates(subCampaign, promotionId) {
    // Find out if a promotion is assigned to a submitted leaflet
    if (isEmpty(subCampaign)) return false;
    const jpPath = `$.resourceDefinitions[*].pages[*].assignment[?(@.promotionId == '${promotionId}')]`;
    const paths = jp.paths(subCampaign, jpPath);
    const resourceWorkflowStates = [];
    paths.forEach(path => {
        const resourceDefinition = cloneDeep(get(subCampaign, path.slice(1, 3)));
        resourceWorkflowStates.push(...get(resourceDefinition, 'workflowState', []));
    });
    return resourceWorkflowStates;
}

function removePromotionsFromInstances(
    subCampaign,
    promotionId,
    resource = null,
    subType = null,
    instanceIndex = null
) {
    // Find and remove the promotionId and products from a leaflet etc at the subcampaign level
    if (isEmpty(subCampaign)) return { pathsToUpdate: [], resourceDefinitions: null };
    let jpPath;
    if (instanceIndex) {
        jpPath = `$.resourceDefinitions[${instanceIndex}].pages[*].assignment[?(@.promotionId == '${promotionId}')]`;
    } else if (resource) {
        jpPath = `$.resourceDefinitions[?(@.resourceKey == '${resource}')].pages[*].assignment[?(@.promotionId == '${promotionId}')]`;
    } else {
        jpPath = `$.resourceDefinitions[*].pages[*].assignment[?(@.promotionId == '${promotionId}')]`;
    }
    const paths = jp.paths(subCampaign, jpPath);
    const pathsToUpdate = [];
    paths.forEach(path => {
        const removalPath = generateRemovalPath(path);
        const currentValue = get(subCampaign, `${removalPath.join('.')}.promotionId`, null);
        if (currentValue) {
            set(subCampaign, `${removalPath.join('.')}.promotionId`, null);
            set(subCampaign, `${removalPath.join('.')}.products`, []);
            pathsToUpdate.push({ resource, subType });
        }
    });
    return { pathsToUpdate, resourceDefinitions: subCampaign.resourceDefinitions };
}

const setNonPromoPricesAndCosts = ({ product, productCosts, productPrices, precision }) => {
    const roundValue = value => {
        return value ? round(value, precision) : 0;
    };

    const nonPromoPrices = {
        minPrice: productPrices ? roundValue(productPrices.minNonPromoPrice) : 0,
        maxPrice: productPrices ? roundValue(productPrices.maxNonPromoPrice) : 0,
        avgPrice: productPrices ? roundValue(productPrices.avgNonPromoPrice) : 0,
        avgPriceExcTax: productPrices ? roundValue(productPrices.avgNonPromoPriceExcTax) : 0,
    };

    const onInvoiceCosts = {
        minCost:
            productCosts && productCosts.onInvoiceCosts
                ? roundValue(productCosts.onInvoiceCosts.minNonPromoCost)
                : 0,
        maxCost:
            productCosts && productCosts.onInvoiceCosts
                ? roundValue(productCosts.onInvoiceCosts.maxNonPromoCost)
                : 0,
        avgCost:
            productCosts && productCosts.onInvoiceCosts
                ? roundValue(productCosts.onInvoiceCosts.avgNonPromoCost)
                : 0,
    };

    const commercialCosts = {
        minCost:
            productCosts && productCosts.commercialCosts
                ? roundValue(productCosts.commercialCosts.minNonPromoCost)
                : 0,
        maxCost:
            productCosts && productCosts.commercialCosts
                ? roundValue(productCosts.commercialCosts.maxNonPromoCost)
                : 0,
        avgCost:
            productCosts && productCosts.commercialCosts
                ? roundValue(productCosts.commercialCosts.avgNonPromoCost)
                : 0,
    };

    return {
        ...product,
        nonPromoPrices,
        onInvoiceCosts,
        commercialCosts,
    };
};

const setNonPromoPricesAndCostsForProducts = ({ promotion, costs, prices, precision }) => {
    const products = promotion.products.map(product => {
        const productPrices = prices[product.proxyProductKey || product.productKey];
        const productCosts = costs[product.proxyProductKey || product.productKey];

        return setNonPromoPricesAndCosts({
            product,
            productCosts,
            productPrices,
            precision,
        });
    });
    return products;
};

/**
 * Reset promotion products (set latest prices and costs, default volumes and forecastingAggregations)
 *
 * @param {Object} RORO - The RORO wrapper
 * @param {Object} RORO.promotion - the promotion.
 * @param {Object} RORO.prices - keyed products prices.
 * @param {Object} RORO.costs - keyed products costs.
 */
const resetPromotionProducts = ({
    promotion,
    prices,
    costs,
    resetOverrides = true,
    resetPercentageVolumeBuffer = true,
    precisionForPricesAndCosts,
    originalProducts = [],
}) => {
    const originalProductsKeyed = keyBy(originalProducts, 'productKey');
    const products = promotion.products.map(product => {
        const productPrices = prices[product.proxyProductKey || product.productKey];
        const productCosts = costs[product.proxyProductKey || product.productKey];

        const { onInvoiceCosts, commercialCosts, nonPromoPrices } = setNonPromoPricesAndCosts({
            product,
            productCosts,
            productPrices,
            precision: precisionForPricesAndCosts,
        });

        const volumes = {
            forecasted: {
                calcBaseline: 0,
                calcUplift: 0,
                triggerCount: null,
            },
            percentageVolumeBuffer: resetPercentageVolumeBuffer
                ? 0
                : get(product, 'volumes.percentageVolumeBuffer', 0),
        };

        if (resetOverrides) {
            volumes.baseline = null;
            volumes.uplift = null;
            volumes.totalVolume = 0;
        } else {
            const hasOriginalProduct =
                !isEmpty(originalProducts) && originalProductsKeyed[product.productKey];
            volumes.baseline = hasOriginalProduct
                ? originalProductsKeyed[product.productKey].volumes.baseline
                : product.volumes.baseline;
            volumes.uplift = hasOriginalProduct
                ? originalProductsKeyed[product.productKey].volumes.uplift
                : product.volumes.uplift;
            volumes.totalVolume = hasOriginalProduct
                ? originalProductsKeyed[product.productKey].volumes.totalVolume
                : product.volumes.totalVolume;
        }

        const forecasts = {
            forecastingAggregations: {
                product: {},
                storeGroups: [],
            },
            forecasts: {
                algorithm: {
                    product: {},
                },
                override: null,
            },
        };

        if (!isNil(product.forecasts ? product.forecasts.reference : null)) {
            forecasts.forecasts.reference = product.forecasts.reference;
        }

        return {
            ...product,
            nonPromoPrices,
            volumes,
            onInvoiceCosts,
            commercialCosts,
            ...forecasts,
        };
    });

    return products;
};

const updateProductFieldsWithCorrectInformation = async ({ products, baseProducts, forPublishing = false }) => {
    const productsKeyedByKey = keyBy(baseProducts, 'productKey');

    return products.map(prod => {
        const baseProductInfo = productsKeyedByKey[prod.productKey];
        const fieldsToPick = [
            'hierarchy',
            'category',
            'attributes',
            'standardWeight',
            ...(
                !forPublishing ? [
                    'supplierKey', 
                    'supplierName',
                    'clientSupplierKey',
                ] : []
            ),
        ];
        return {
            ...prod,
            ...pick(baseProductInfo, fieldsToPick),
        };
    });
};

/**
 * Function to restrict promotions based on user categories. User must have access to at least one of categories
 * in a promotion in order to see it.
 *
 * @param {Array} userCategories - The list of user categories that can be used to create the filter
 */
const createNotStrictPromotionAccessFilterWithAccessToEmptyPromotions = async ({
    userCategories = [],
    hasAccessToAllSubCampaignCategories,
}) => {
    // promotion must be empty or user must have access to at least one category in a promotion in order to see it

    let emptyPOGFilter = {
        $and: [
            { userSelectedCategories: { $size: 0 } },
            {
                $or: [
                    { 'productOfferGroups.scope': pogScope.selectedProducts },
                    { productOfferGroups: { $eq: [] } },
                ],
            },
        ],
    };
    if (hasAccessToAllSubCampaignCategories) {
        // include store-wide promotions
        emptyPOGFilter = { userSelectedCategories: { $size: 0 } };
    }

    return {
        $or: [
            { 'userSelectedCategories.levelEntryKey': { $in: userCategories } },
            { ...emptyPOGFilter },
        ],
    };
};

/**
 * Set child promotion product funding
 * guaranted margin buying price === per unit cost
 * guaranted margin supplier compensation  === per unit discount
 *
 * @param {Object} params - The params.
 * @param {Object} params.product - promotion product to set funding
 *
 * @returns {Number} - The product's funding.
 */
const setChildFunding = ({ product }) => {
    const va = get(product, 'funding.variableFunding');

    product.funding = {
        lumpFunding: [],
        lumpFundingTotal: 0,
        rebate: 0,
        rebateMultiplier: 0,
        variableFunding: {
            uiFields: {
                onInvoiceValue: null,
                onInvoiceType: null,
                offInvoiceValue: null,
                offInvoiceType: null,
            },
            sellInPeriod: va.sellInPeriod,
            unitFundingValue: va.unitFundingValue,
            supplierCompensation: va.supplierCompensation,
            buyingPrice: va.buyingPrice,
        },
    };
};

/**
 * Gets totalVolume at promotion level.
 * Based on forecast type and products baseline + uplift
 *
 * @param {String} forecastType - algorithm, override or reference
 * @param {Array} products - list of products inside a promotion
 */
const getPromotionTotalVolume = ({ forecastType, products }) => {
    return products.reduce(
        (totalVolume, product) =>
            totalVolume +
            (get(product.forecasts, `${forecastType}.product.volumes.totalVolume`) || 0),
        0
    );
};

const getPromotionStatus = ({ moment, startDate, isInParkingLot }) => {
    const today = moment();
    const isAfterStartDate = today.isAfter(moment(startDate));

    let state;
    if (!isInParkingLot) {
        state = isAfterStartDate ? parkingLotStates.executed : parkingLotStates.planned;
    } else state = parkingLotStates.available;

    return state;
};

/**
 * Update the overridden uplift and baseline values to keep the total volume consistent
 * after reforecasting.
 *
 * @param {object} originalPromotion - The promotion to update the overrides on
 * @param {object} promotionAfterForecasting - The promotion with updated forecasting results.
 */
function updateOverridesToFixTotalVolume({ originalPromotion, promotionAfterForecasting }) {
    // Create a keyed lookup of products without overrides.
    const keyedProductsWithoutOverrides = keyBy(promotionAfterForecasting.products, 'productKey');

    originalPromotion.products.forEach(product => {
        // Verify if this product has overrides. If not, we can skip it.
        if (isNil(product.volumes.uplift) && isNil(product.volumes.baseline)) return;

        const productWithoutOverrides = keyedProductsWithoutOverrides[product.productKey];

        // If we are missing a forecast for a product then it means that
        // forecasting failed and hence we can skip updating the volume for this product
        // as there are no new forecasted Basline or Uplift that could change the total
        if (isNil(productWithoutOverrides)) return;

        const oldVolumes = product.volumes.forecasted;
        const newVolumes = productWithoutOverrides.volumes.forecasted;

        // Check if the the calculated baseline and volume have changed
        // If they have not, then we do not need to progress any further.
        if (
            newVolumes.calcBaseline === oldVolumes.calcBaseline &&
            newVolumes.calcUplift === oldVolumes.calcUplift
        ) {
            return;
        }

        const fixedTotal = product.volumes.totalVolume;

        // If fixed total is larger than the new calcBaseline, we need to set
        // the override baseline to this value and the uplift to 0
        if (fixedTotal <= newVolumes.calcBaseline) {
            product.volumes.baseline = fixedTotal;
            product.volumes.uplift = 0;
        } else {
            // fixedTotal > baseline
            product.volumes.baseline = null;
            product.volumes.uplift = Number((fixedTotal - newVolumes.calcBaseline).toFixed(2));
        }
    });
}

function updateProductsLumpFunding({ products, rateCardsOnPromotion }) {
    return products.map(product => {
        const lumpFunding = get(product, 'funding.lumpFunding', []);
        const updatedLumpFunding = lumpFunding.filter(lf =>
            rateCardsOnPromotion.has(String(lf.rateCardId))
        );
        set(product, 'funding.lumpFunding', updatedLumpFunding);
        const newLumpFundingTotal = product.funding.lumpFunding.reduce((sum, item) => {
            return sum + item.rateCardAmount;
        }, 0);
        product.funding.lumpFundingTotal = newLumpFundingTotal;

        return product;
    });
}

function isStoreWidePromotion(promotion) {
    return (
        promotion &&
        !isEmpty(promotion.productOfferGroups) &&
        promotion.productOfferGroups.some(pog => pog.scope === pogScope.storeWide)
    );
}

function isCategoryWidePromotion(promotion) {
    return (
        promotion &&
        !isEmpty(promotion.productOfferGroups) &&
        promotion.productOfferGroups.some(pog => pog.scope === pogScope.categoryWide)
    );
}

function isCategoryOrStoreWidePromotion(promotion) {
    return isStoreWidePromotion(promotion) || isCategoryWidePromotion(promotion);
}

/**
 * Reapportion rate-cards after category or supplier updates.
 * Users apportion rate-cards at the supplier-category level in the UI,
 * although we save these at the product level.
 * So we need to make sure that the supplier-category lump funding value
 * remains the same when products move to a different combination,
 * unless the previous combination does no exist anymore, in that case, the funding moves along.
 *
 * @param {Object} productMapping - mapping of product keys with previous and new supplier-category combination
 * @param {Object} promotion - promotion to be reapportioned
 * @param {Object} rateCard - rate-card to apportion
 * @param {Function} apportionFunction - apportioning function
 */
const reapportionRateCardFunding = async ({
    productMapping,
    promotion,
    rateCard,
    apportionFunction,
}) => {
    // Build up array containing each product and their old/new supplier category combinations
    // along with the funding for the rate card currently being processed.
    const productFunding = promotion.products.map(
        ({ productKey, supplierKey, funding, category }) => {
            const lumpFunding = find(funding.lumpFunding, { rateCardId: rateCard._id }) || {
                rateCardAmount: 0,
            };

            const previousSupplier = productMapping[productKey]
                ? productMapping[productKey].previousSupplierKey || supplierKey
                : supplierKey;
            const newSupplier = productMapping[productKey]
                ? productMapping[productKey].newSupplierKey || supplierKey
                : supplierKey;
            const previousCategory = productMapping[productKey]
                ? productMapping[productKey].previousCategoryKey || category
                : category;
            const newCategory = productMapping[productKey]
                ? productMapping[productKey].newCategoryKey || category
                : category;

            return {
                productKey,
                previousCategorySupplier: `${previousCategory}___${previousSupplier}`,
                newCategorySupplier: `${newCategory}___${newSupplier}`,
                fundingAmount: lumpFunding.rateCardAmount,
            };
        }
    );

    // List of unique combination keys, old and new.
    const previousCategorySuppliers = uniq(map(productFunding, 'previousCategorySupplier'));
    const newCategorySuppliers = uniq(map(productFunding, 'newCategorySupplier'));
    const distinctCategorySuppliers = union(previousCategorySuppliers, newCategorySuppliers);

    // List of combination keys which haven't changed, as they don't need to be recalculated.
    const staticCategorySuppliers = distinctCategorySuppliers.filter(categorySupplier => {
        return !some(productFunding, ({ previousCategorySupplier, newCategorySupplier }) => {
            return (
                (previousCategorySupplier !== categorySupplier &&
                    newCategorySupplier === categorySupplier) ||
                (previousCategorySupplier === categorySupplier &&
                    newCategorySupplier !== categorySupplier)
            );
        });
    });

    // Remove products whose new and old supplier-category combinations aren't involved in the changes.
    remove(productFunding, ({ previousCategorySupplier }) =>
        includes(staticCategorySuppliers, previousCategorySupplier)
    );

    // Next, find any keys which no longer exist after the update.
    // For these cases, we want the funding to "move with the product".
    // This can be accomplished by setting the previousCategorySupplier to be the newCategorySupplier.
    const removedCategorySuppliers = new Set(
        difference(previousCategorySuppliers, newCategorySuppliers)
    );
    productFunding.forEach(product => {
        if (removedCategorySuppliers.has(product.previousCategorySupplier)) {
            product.previousCategorySupplier = product.newCategorySupplier;
        }
    });

    // Now calculating the funding amount for each category-supplier combination.
    const categorySuppliersFunding = reduce(
        productFunding,
        (acc, { previousCategorySupplier, fundingAmount }) => {
            if (acc[previousCategorySupplier]) {
                acc[previousCategorySupplier] += fundingAmount;
            } else {
                acc[previousCategorySupplier] = fundingAmount;
            }

            return acc;
        },
        {}
    );

    // Finally, reapportion the funding in each category-supplier combination.
    forEach(newCategorySuppliers, newCategorySupplier => {
        // no need to recalculate funding for staticCategorySuppliers
        // moreover, this product's data was removed from productFunding earlier
        // and doesn't exist in categorySuppliersFunding after removing
        if (staticCategorySuppliers.includes(newCategorySupplier)) {
            return;
        };

        const [category, supplier] = newCategorySupplier.split('___');
        const productsFromPromotion = promotion.products.filter(
            product => product.category === category && product.supplierKey.toString() === supplier
        );

        const fundingAmount = categorySuppliersFunding[newCategorySupplier];

        const apportionedValues = apportionFunction({
            products: productsFromPromotion,
            valueToApportion: fundingAmount,
            getWeight: apportionMappings.tmpLumpFunding.getWeight,
            decimalPlace: 0,
        });

        forEach(productsFromPromotion, (product, index) => {
            const rateCardToUpdate = find(product.funding.lumpFunding, {
                rateCardId: rateCard._id,
            });
            if (rateCardToUpdate) {
                rateCardToUpdate.rateCardAmount = apportionedValues[index] || 0;
            }
        });
    });
};

module.exports = {
    assignedPromotionsIds,
    isPromotionAssigned,
    resetPromotionProducts,
    removeProductFromSubCampaignHeroProducts,
    removePromotionsFromInstances,
    setChildFunding,
    resourcesPromotionIsAssignedToWorkflowStates,
    getPromotionTotalVolume,
    getPromotionStatus,
    setNonPromoPricesAndCostsForProducts,
    createNotStrictPromotionAccessFilterWithAccessToEmptyPromotions,
    updateOverridesToFixTotalVolume,
    updateProductsLumpFunding,
    isStoreWidePromotion,
    isCategoryWidePromotion,
    isCategoryOrStoreWidePromotion,
    updateProductFieldsWithCorrectInformation,
    reapportionRateCardFunding,
};
