import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "redis-bull" }, [
    _c(
      "div",
      { staticClass: "redis-bull__queue-operations" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("vuex-select", {
          staticClass: "redis-bull__queue-operations--select-queue",
          attrs: {
            getter: () => _vm.selectedQueue,
            setter: (value) => _vm.setSelectedQueue(value),
            options: _vm.queueOptions,
            placeholder: _vm._f("toSentenceCase")(_vm.$tkey(`selectedQueue`)),
            "item-text": "queueName",
            "item-value": "queueId",
          },
        }),
        _vm._v(" "),
        _c("vuex-select", {
          staticClass: "redis-bull__queue-operations--select-operation",
          attrs: {
            getter: () => _vm.selectedOperation,
            setter: (value) => _vm.setSelectedOperation(value),
            options: _vm.operationOptions,
            disabled: !_vm.selectedQueue,
            placeholder: _vm._f("toSentenceCase")(
              _vm.$tkey(`selectedOperation`)
            ),
            "item-text": "operation",
            "item-value": "operationId",
          },
        }),
        _vm._v(" "),
        _c(
          VBtn,
          {
            staticClass: "redis-bull__queue-operations--submit",
            attrs: {
              depressed: "",
              color: "primary",
              disabled: !_vm.selectedQueue || !_vm.selectedOperation,
            },
            on: {
              click: function ($event) {
                return _vm.submit()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$tkey(`submit`))) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c("confirm-dialog", {
          ref: "cofirmOperationDialog",
          staticClass: "redis-bull__queue-operations--confirm-dialog",
          attrs: {
            "question-text": _vm._f("toSentenceCase")(
              _vm.$tkey(`questionText`)
            ),
            "warning-text": _vm.confirmWarning,
            "action-text": _vm._f("toSentenceCase")(_vm.$tkey("confirm")),
            "has-activator": false,
          },
          on: {
            confirm: function ($event) {
              return _vm.confirm()
            },
            close: function ($event) {
              return _vm.cancelDialog()
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.numberOfEffectedJobs !== null
      ? _c("div", { staticClass: "redis-bull__queue-message" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm._f("toSentenceCase")(
                  _vm.$tkey("jobsRemoved", {
                    numberOfEffectedJobs: _vm.numberOfEffectedJobs,
                  })
                )
              ) +
              "\n    "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "redis-bull__bulk-add" },
      [
        _vm._m(1),
        _vm._v(" "),
        _c("vuex-select", {
          staticClass: "redis-bull__bulk-add--select-queue",
          attrs: {
            getter: () => _vm.selectedBulkAddQueue,
            setter: (value) => _vm.setSelectedBulkAddQueue(value),
            options: _vm.bulkAddsQueueOptions,
            placeholder: _vm._f("toSentenceCase")(_vm.$tkey(`selectedQueue`)),
            "item-text": "queueName",
            "item-value": "queueId",
          },
        }),
        _vm._v(" "),
        _c("vuex-date-range", {
          key: _vm.dateRange.length,
          staticClass: "redis-bull__bulk-add--date-range",
          attrs: {
            from: { fieldName: "from" },
            to: { fieldName: "to" },
            "use-vuex-staging-area": false,
            getter: () => _vm.dateRange,
            "min-date": _vm.minDateRangeDate,
            disabled: !_vm.selectedBulkAddQueue,
          },
          on: { change: _vm.dateRangeSelected },
        }),
        _vm._v(" "),
        _c(VTextField, {
          staticClass: "redis-bull__bulk-add--filter",
          attrs: {
            filled: "",
            clearable: "",
            flat: "",
            placeholder: 'Mongo filter e.g. { "isInParkingLot": false }',
            disabled: !_vm.selectedBulkAddQueue,
          },
          model: {
            value: _vm.filterText,
            callback: function ($$v) {
              _vm.filterText = $$v
            },
            expression: "filterText",
          },
        }),
        _vm._v(" "),
        _c(VCheckbox, {
          staticClass: "redis-bull__bulk-add--checkbox",
          attrs: { disabled: !_vm.selectedBulkAddQueue },
          model: {
            value: _vm.includeGhosts,
            callback: function ($$v) {
              _vm.includeGhosts = $$v
            },
            expression: "includeGhosts",
          },
        }),
        _vm._v(" "),
        _c(
          VBtn,
          {
            staticClass: "redis-bull__bulk-add--submit",
            attrs: {
              depressed: "",
              color: "primary",
              disabled:
                !_vm.selectedBulkAddQueue ||
                !_vm.selectedBulkAddDateFilter ||
                !_vm.filterText,
            },
            on: {
              click: function ($event) {
                return _vm.submitAddToQueue()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$tkey(`submit`))) +
                "\n        "
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.numberOfAddedPromotions !== null
      ? _c("div", { staticClass: "redis-bull__queue-message" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm._f("toSentenceCase")(
                  _vm.$tkey("jobsAdded", {
                    numberOfAddedPromotions: _vm.numberOfAddedPromotions,
                  })
                )
              ) +
              "\n    "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "redis-bull__batch-job" },
      [
        _vm._m(2),
        _vm._v(" "),
        _c("vuex-select", {
          staticClass: "redis-bull__batch-job--select-queue",
          attrs: {
            getter: () => _vm.selectedBatchJob,
            setter: (value) => _vm.setSelectedBatchJob(value),
            options: _vm.batchJobsOptions,
            placeholder: _vm._f("toSentenceCase")(_vm.$tkey(`selectedJob`)),
            "item-text": "jobName",
            "item-value": "jobId",
          },
        }),
        _vm._v(" "),
        _c("vuex-date-range", {
          key: _vm.dateRange.length,
          staticClass: "redis-bull__batch-job--date-range",
          attrs: {
            from: { fieldName: "from" },
            to: { fieldName: "to" },
            "use-vuex-staging-area": false,
            getter: () => _vm.jobDateRange,
            disabled: !_vm.selectedBatchJob,
          },
          on: { change: _vm.jobDateRangeSelected },
        }),
        _vm._v(" "),
        _c(VTextField, {
          staticClass: "redis-bull__batch-job--filter",
          attrs: {
            filled: "",
            clearable: "",
            flat: "",
            placeholder: 'Mongo filter e.g. { "isInParkingLot": false }',
            disabled: !_vm.selectedBatchJob,
          },
          model: {
            value: _vm.batchJobFilterText,
            callback: function ($$v) {
              _vm.batchJobFilterText = $$v
            },
            expression: "batchJobFilterText",
          },
        }),
        _vm._v(" "),
        _c(
          VBtn,
          {
            staticClass: "redis-bull__batch-job--submit",
            attrs: {
              depressed: "",
              color: "primary",
              disabled: _vm.disabledBatchJobForFilter,
            },
            on: {
              click: function ($event) {
                return _vm.submitBatchJob()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$tkey(`submit`))) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.numberOfProcessedPromotions !== null
          ? _c("div", { staticClass: "redis-bull__queue-message" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$tkey("jobsProcessed", {
                        numberOfProcessedIds: _vm.numberOfProcessedPromotions,
                      })
                    )
                  ) +
                  "\n        "
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "redis-bull__batch-job" },
      [
        _vm._m(3),
        _vm._v(" "),
        _c("vuex-select", {
          staticClass: "redis-bull__batch-job--select-queue",
          attrs: {
            getter: () => _vm.selectedBatchJobForIds,
            setter: (value) => _vm.setSelectedBatchJobForIds(value),
            options: _vm.batchJobForIdsOptions,
            placeholder: _vm._f("toSentenceCase")(_vm.$tkey(`selectedJob`)),
            "item-text": "jobName",
            "item-value": "jobId",
          },
        }),
        _vm._v(" "),
        _c(VTextField, {
          staticClass: "redis-bull__batch-job--filter",
          attrs: {
            filled: "",
            clearable: "",
            flat: "",
            placeholder: 'Mongo filter e.g. { "isInParkingLot": false }',
            disabled: !_vm.selectedBatchJobForIds,
          },
          model: {
            value: _vm.batchJobIdsText,
            callback: function ($$v) {
              _vm.batchJobIdsText = $$v
            },
            expression: "batchJobIdsText",
          },
        }),
        _vm._v(" "),
        _c(VCheckbox, {
          attrs: { label: "isDeleted" },
          model: {
            value: _vm.isDeleted,
            callback: function ($$v) {
              _vm.isDeleted = $$v
            },
            expression: "isDeleted",
          },
        }),
        _vm._v(" "),
        _c(VCheckbox, {
          attrs: { label: "isGhost" },
          model: {
            value: _vm.isGhost,
            callback: function ($$v) {
              _vm.isGhost = $$v
            },
            expression: "isGhost",
          },
        }),
        _vm._v(" "),
        _c(
          VBtn,
          {
            staticClass: "redis-bull__batch-job--submit",
            attrs: {
              depressed: "",
              color: "primary",
              disabled: !_vm.selectedBatchJobForIds || !_vm.batchJobIdsText,
            },
            on: {
              click: function ($event) {
                return _vm.submitBatchJobForIds()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$tkey(`submit`))) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.numberOfProcessedIds !== null
          ? _c("div", { staticClass: "redis-bull__queue-message" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$tkey("jobsProcessed", {
                        numberOfProcessedIds: _vm.numberOfProcessedIds,
                      })
                    )
                  ) +
                  "\n        "
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "redis-bull__bulk-add" },
      [
        _vm._m(4),
        _vm._v(" "),
        _c("vuex-date-range", {
          key: _vm.productsMismatchDateRange.length,
          staticClass: "redis-bull__bulk-add--date-range",
          attrs: {
            from: { fieldName: "from" },
            to: { fieldName: "to" },
            "use-vuex-staging-area": false,
            getter: () => _vm.productsMismatchDateRange,
            "min-date": _vm.minDateRangeDate,
          },
          on: { change: _vm.productsMismatchDateRangeSelected },
        }),
        _vm._v(" "),
        _c(VCheckbox, {
          attrs: { label: "fixGhosts" },
          model: {
            value: _vm.fixGhostsForMismatches,
            callback: function ($$v) {
              _vm.fixGhostsForMismatches = $$v
            },
            expression: "fixGhostsForMismatches",
          },
        }),
        _vm._v(" "),
        _c(
          VBtn,
          {
            staticClass: "redis-bull__bulk-add--submit",
            attrs: {
              depressed: "",
              color: "primary",
              disabled: !(_vm.productsMismatchDateRange.length > 1),
            },
            on: {
              click: function ($event) {
                return _vm.fixProductsMismatchForDates()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$tkey(`submit`))) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.numberOfFixedMismatchingProductsPromotions !== null
          ? _c("div", { staticClass: "redis-bull__queue-message" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$tkey("promotionsFixed", {
                        numberOfPromotionsFixed:
                          _vm.numberOfFixedMismatchingProductsPromotions,
                      })
                    )
                  ) +
                  "\n        "
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "redis-bull__batch-job" },
      [
        _vm._m(5),
        _vm._v(" "),
        _c(VTextarea, {
          staticClass: "redis-bull__batch-job--textarea",
          attrs: {
            filled: "",
            clearable: "",
            flat: "",
            placeholder: "promoA,promoB",
          },
          model: {
            value: _vm.promoIdsToResync,
            callback: function ($$v) {
              _vm.promoIdsToResync = $$v
            },
            expression: "promoIdsToResync",
          },
        }),
        _vm._v(" "),
        _c(
          VBtn,
          {
            staticClass: "redis-bull__batch-job--submit",
            attrs: {
              depressed: "",
              color: "primary",
              disabled: !_vm.promoIdsToResync,
            },
            on: {
              click: function ($event) {
                return _vm.resyncChildIds()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$tkey(`submit`))) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.numberOfResyncedIds !== null
          ? _c("div", { staticClass: "redis-bull__queue-message" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$tkey("promotionsResynced", {
                        numberOfResyncedIds: _vm.numberOfResyncedIds,
                      })
                    )
                  ) +
                  "\n        "
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "redis-bull__batch-job" },
      [
        _vm._m(6),
        _vm._v(" "),
        _c(
          VBtn,
          {
            staticClass: "redis-bull__batch-job--submit",
            attrs: { depressed: "", color: "primary" },
            on: {
              click: function ($event) {
                return _vm.fixingParkingLotPromosWhereWasInParkingLotBecameFalse()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$tkey(`submit`))) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.numberOfFixedItemsForWasInParkingLot !== null
          ? _c("div", { staticClass: "redis-bull__queue-message" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$tkey("wasInParkingLotFix", {
                        numberOfFixedItemsForWasInParkingLot:
                          _vm.numberOfFixedItemsForWasInParkingLot,
                      })
                    )
                  ) +
                  "\n        "
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "redis-bull__batch-job" },
      [
        _vm._m(7),
        _vm._v(" "),
        _c(VTextField, {
          staticClass: "redis-bull__batch-job--text",
          attrs: {
            filled: "",
            clearable: "",
            flat: "",
            placeholder: "Field to be added",
          },
          model: {
            value: _vm.addMissingFieldToPromosPayload.field,
            callback: function ($$v) {
              _vm.$set(_vm.addMissingFieldToPromosPayload, "field", $$v)
            },
            expression: "addMissingFieldToPromosPayload.field",
          },
        }),
        _vm._v(" "),
        _c(VTextField, {
          staticClass: "redis-bull__batch-job--text",
          attrs: {
            filled: "",
            clearable: "",
            flat: "",
            placeholder: "Field value",
            disabled: !_vm.addMissingFieldToPromosPayload.field,
          },
          model: {
            value: _vm.addMissingFieldToPromosPayload.value,
            callback: function ($$v) {
              _vm.$set(_vm.addMissingFieldToPromosPayload, "value", $$v)
            },
            expression: "addMissingFieldToPromosPayload.value",
          },
        }),
        _vm._v(" "),
        _c(VTextField, {
          staticClass: "redis-bull__batch-job--filter",
          attrs: {
            filled: "",
            clearable: "",
            flat: "",
            placeholder: 'Mongo filter e.g. { "isInParkingLot": false }',
            disabled: !_vm.addMissingFieldToPromosPayload.field,
          },
          model: {
            value: _vm.addMissingFieldToPromosPayload.filter,
            callback: function ($$v) {
              _vm.$set(_vm.addMissingFieldToPromosPayload, "filter", $$v)
            },
            expression: "addMissingFieldToPromosPayload.filter",
          },
        }),
        _vm._v(" "),
        _c(
          VBtn,
          {
            staticClass: "redis-bull__batch-job--submit",
            attrs: {
              depressed: "",
              color: "primary",
              disabled: !_vm.addMissingFieldToPromosPayload.field,
            },
            on: {
              click: function ($event) {
                return _vm.submitAddMissingFieldToPromos()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$tkey(`submit`))) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.numberOfFixedPromotions !== null
          ? _c("div", { staticClass: "redis-bull__queue-message" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$tkey("promotionsFixed", {
                        numberOfPromotionsFixed: _vm.numberOfFixedPromotions,
                      })
                    )
                  ) +
                  "\n        "
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "redis-bull__batch-job" },
      [
        _vm._m(8),
        _vm._v(" "),
        _c(
          VBtn,
          {
            staticClass: "redis-bull__batch-job--submit",
            attrs: { depressed: "", color: "primary" },
            on: {
              click: function ($event) {
                return _vm.fixingStartEndDates()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$tkey(`submit`))) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.numberOfFixedItemsWithWrongDates !== null
          ? _c("div", { staticClass: "redis-bull__queue-message" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$tkey("promotionsFixed", {
                        numberOfPromotionsFixed:
                          _vm.numberOfFixedItemsWithWrongDates,
                      })
                    )
                  ) +
                  "\n        "
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "redis-bull__bulk-add" },
      [
        _vm._m(9),
        _vm._v(" "),
        _c("vuex-date-range", {
          key: _vm.isManualPromotionsDateRange.length,
          staticClass: "redis-bull__bulk-add--date-range",
          attrs: {
            from: { fieldName: "from" },
            to: { fieldName: "to" },
            "use-vuex-staging-area": false,
            getter: () => _vm.isManualPromotionsDateRange,
          },
          on: { change: _vm.isManualPromotionsDateRangeSelected },
        }),
        _vm._v(" "),
        _c(VCheckbox, {
          attrs: { label: "Confrim to move promotions in manual mode" },
          model: {
            value: _vm.confirmCheck,
            callback: function ($$v) {
              _vm.confirmCheck = $$v
            },
            expression: "confirmCheck",
          },
        }),
        _vm._v(" "),
        _c(
          VBtn,
          {
            staticClass: "redis-bull__bulk-add--submit",
            attrs: {
              depressed: "",
              color: "primary",
              disabled:
                !(_vm.isManualPromotionsDateRange.length > 1) ||
                !_vm.confirmCheck,
            },
            on: {
              click: function ($event) {
                return _vm.moveChildrenPromotionsToManual()
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$tkey(`submit`))) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.numberOfPromotionsMovedToManual !== null
          ? _c("div", { staticClass: "redis-bull__queue-message" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$tkey("promotionsFixed", {
                        numberOfPromotionsFixed:
                          _vm.numberOfPromotionsMovedToManual,
                      })
                    )
                  ) +
                  "\n        "
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "redis-bull__queue-operations--title" }, [
      _c("b", [_vm._v("Perform Queue Operation:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "redis-bull__bulk-add--title" }, [
      _c("b", [_vm._v("Add promotions to queue:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "redis-bull__batch-job--title" }, [
      _c("b", [_vm._v("Submit batch job for filter:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "redis-bull__batch-job--title" }, [
      _c("b", [_vm._v("Submit batch job for ids:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "redis-bull__bulk-add--title" }, [
      _c("b", [_vm._v("Fix products mismatch on promotions:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "redis-bull__batch-job--title" }, [
      _c("b", [_vm._v("Resync Child ids")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "redis-bull__batch-job--title" }, [
      _c("b", [_vm._v("Fix promotions where wasInParkingLot became false")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "redis-bull__batch-job--title" }, [
      _c("b", [_vm._v("Add missing field to promotions")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "redis-bull__batch-job--title" }, [
      _c("b", [_vm._v("Fix promotions where start and end dates are string")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "redis-bull__bulk-add--title" }, [
      _c("b", [
        _vm._v(
          "\n                Move children promotion to the manual mode.\n                "
        ),
        _c("span", { staticStyle: { color: "red" } }, [
          _vm._v(
            "\n                    Can bring inconsistencies confirm checkbox requierd:\n                "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }