import Vue from 'vue';
// import Vuex, { createLogger } from 'vuex';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import { fieldTypes } from '@enums/vuex-form';
import filterEnums from '@enums/filters';
import { uniqueId, find, omit, isEmpty, get, isArray, forEach } from 'lodash';
import i18n from '../vue-i18n';
import { toSentenceCase } from '@/js/utils/string-utils';

import workPackages from './modules/work-packages';
import context from './modules/context';
import campaigns from './modules/campaigns';
import subCampaigns from './modules/sub-campaigns';
import scenarios from './modules/scenarios';
import notifications from './modules/notifications';
import promotions from './modules/promotions';
import promotionVersions from './modules/promotion-versions';
import promotionProductGroups from './modules/promotion-product-groups';
import products from './modules/products';
import selectedDates from './modules/selected-dates';
import storeGroups from './modules/store-groups';
import promotionCandidates from './modules/promotion-candidates';
import hierarchy from './modules/hierarchy';
import clientConfig from './modules/client-config';
import productPrices from './modules/product-prices';
import productCosts from './modules/product-costs';
import weeks from './modules/weeks';
import forecasting from './modules/forecasting';
import attributeMetadata from './modules/attribute-metadata';
import nominationTemplates from './modules/nomination-templates';
import supplierCommitments from './modules/supplier-commitments';
import suppliers from './modules/suppliers';
import rateCards from './modules/rate-cards';
import variableFundingAgreements from './modules/variable-funding-agreements';
import tagMetadata from './modules/tag-metadata';
import freeGifts from './modules/free-gifts';
import reporting from './modules/reporting';
import bookmarks from './modules/bookmarks';
import execution from './modules/execution';
import loyaltyPoints from './modules/loyalty-points';
import userProfiles from './modules/user-profiles';
import targets from './modules/targets';
import redisBull from './modules/redis-bull';
import offerMechanicPresets from './modules/offer-mechanic-presets';

Vue.use(Vuex);

const filterKeySetter = ({ index, filterId, key, filters, filterDefinitions }) => {
    const filter = filterId ? find(filters, { filterId }) : filters[index];
    const filterDefinition = find(filterDefinitions, { filterKey: key });
    Vue.set(filter, 'filterType', filterDefinition.filterType);
    Vue.set(filter, 'filterKey', key);
    Vue.set(filter, 'filterValue', null);
};

const filterValueSetter = ({ index, filterId, value, filters }) => {
    const filter = filterId ? find(filters, { filterId }) : filters[index];
    Vue.set(filter, 'filterValue', value);
};

// TODO: This code is present for the scenario where data is to be persisted across
// multiple tabs, however we don't believe this is currently working.
const vuexLocalStorage = new VuexPersist({
    key: 'store',
    storage: window.localStorage,
    reducer: state => ({
        context: state.context,
        workPackages: state.workPackages,
    }),
});

const createEmptyFilter = () => ({
    filterKey: null,
    filterValue: null,
    filterId: uniqueId(),
});

const getInitialState = () => ({
    globalFilterDefinitions: [
        {
            filterText: toSentenceCase(i18n.t('planning.filterBar.categories')),
            filterKey: filterEnums.categories,
            module: 'hierarchy',
            optionsRootGetter: 'context/userCategories',
            filterValueMapping: {
                text: 'levelEntryDescription',
                value: 'levelEntryKey',
            },
            filterType: fieldTypes.select,
        },
        {
            filterText: toSentenceCase(i18n.t('planning.filterBar.storeGroups')),
            filterKey: filterEnums.storeGroups,
            module: 'storeGroups',
            optionsRootGetter: 'context/userStoreGroups',
            filterValueMapping: {
                text: 'description',
                value: 'key',
            },
            filterType: fieldTypes.select,
            objectKey: 'key',
        },
        {
            filterText: toSentenceCase(i18n.t('planning.filterBar.tags')),
            filterKey: filterEnums.tags,
            module: 'tagMetadata',
            optionsRootGetter: 'tagMetadata/getTagOptions',
            filterValueMapping: {
                text: 'tagName',
                value: 'tagKey',
            },
            filterType: fieldTypes.select,
            objectKey: 'tagKey',
        },
        {
            filterText: toSentenceCase(i18n.t('planning.filterBar.clientState')),
            filterKey: filterEnums.clientState,
            module: 'promotions',
            optionsRootGetter: 'clientConfig/getPromotionClientStatesMapForPlanningPageFilter',
            filterValueMapping: {
                text: 'stateName',
                value: 'state',
            },
            filterType: fieldTypes.select,
            sortResults: false,
        },
    ],
    globalFilters: [createEmptyFilter()],
    suppliersFilterDefinitions: [
        {
            filterText: toSentenceCase(i18n.t('variableFundingAgreements.filterBar.categories')),
            filterKey: filterEnums.categories,
            module: 'suppliers',
            optionsRootGetter: 'context/userCategories',
            filterValueMapping: {
                text: 'levelEntryDescription',
                value: 'levelEntryKey',
            },
            filterType: fieldTypes.select,
            isSingle: true,
            isMain: true,
        },
        {
            filterText: toSentenceCase(i18n.t('variableFundingAgreements.filterBar.suppliers')),
            filterKey: filterEnums.suppliers,
            module: 'suppliers',
            optionsRootGetter: 'suppliers/getSuppliersByCategories',
            filterValueMapping: {
                text: 'name',
                value: 'supplierKey',
            },
            filterType: fieldTypes.select,
        },
    ],
    defaultSuppliersFilterDefinitions: [
        {
            filterText: toSentenceCase(i18n.t('variableFundingAgreements.filterBar.categories')),
            filterKey: filterEnums.categories,
            module: 'suppliers',
            optionsRootGetter: 'context/userCategories',
            filterValueMapping: {
                text: 'levelEntryDescription',
                value: 'levelEntryKey',
            },
            filterType: fieldTypes.select,
            isSingle: true,
            isMain: true,
        },
        {
            filterText: toSentenceCase(i18n.t('variableFundingAgreements.filterBar.suppliers')),
            filterKey: filterEnums.suppliers,
            module: 'suppliers',
            optionsRootGetter: 'suppliers/getSuppliersByCategories',
            filterValueMapping: {
                text: 'name',
                value: 'supplierKey',
            },
            filterType: fieldTypes.select,
        },
    ],
    totalSpendSupplierCommitmentsFilterDefinitions: [
        {
            filterText: toSentenceCase(i18n.t('variableFundingAgreements.filterBar.year')),
            filterKey: filterEnums.year,
            module: 'suppliers',
            optionsRootGetter: 'weeks/getYearOptions',
            filterValueMapping: {
                text: 'value',
                value: 'key',
            },
            filterType: fieldTypes.select,
            isSingle: true,
            isMain: true,
        },
        {
            filterText: toSentenceCase(i18n.t('variableFundingAgreements.filterBar.categories')),
            filterKey: filterEnums.categories,
            module: 'suppliers',
            optionsRootGetter: 'context/userCategories',
            filterValueMapping: {
                text: 'levelEntryDescription',
                value: 'levelEntryKey',
            },
            filterType: fieldTypes.select,
            isSingle: true,
            isMain: true,
        },
        {
            filterText: toSentenceCase(i18n.t('variableFundingAgreements.filterBar.suppliers')),
            filterKey: filterEnums.suppliers,
            module: 'suppliers',
            optionsRootGetter: 'suppliers/getSuppliersByCategories',
            filterValueMapping: {
                text: 'name',
                value: 'supplierKey',
            },
            filterType: fieldTypes.select,
        },
        {
            filterText: toSentenceCase(i18n.t('variableFundingAgreements.filterBar.storeGroups')),
            filterKey: filterEnums.storeGroups,
            module: 'suppliers',
            optionsRootGetter: 'storeGroups/getStoreGroups',
            filterValueMapping: {
                text: 'description',
                value: 'key',
            },
            filterType: fieldTypes.select,
        },
    ],
    suppliersFilters: [createEmptyFilter()],
    parkingLotFilters: {
        options: {
            campaignOptions: [],
            subCampaignOptions: [],
            scenarioOptions: [],
        },
        selections: {
            dateRange: null,
            campaign: null,
            subCampaign: null,
            scenario: null,
        },
    },
    isNavigating: false,
});

const store = new Vuex.Store({
    state: getInitialState(),

    getters: {},

    mutations: {
        resetGlobalFilters(state) {
            state.globalFilters = [createEmptyFilter()];
        },
        removeGlobalFilter(state, index) {
            Vue.delete(state.globalFilters, index);
        },
        addGlobalFilter(state) {
            // push new empty filter to globalFilters
            Vue.set(state.globalFilters, state.globalFilters.length, createEmptyFilter());
        },
        globalFilterKeySetter(state, { index, filterId, key }) {
            filterKeySetter({
                index,
                filterId,
                key,
                filters: state.globalFilters,
                filterDefinitions: state.globalFilterDefinitions,
            });
        },
        globalFilterValueSetter(state, { index, filterId, value }) {
            filterValueSetter({ index, filterId, value, filters: state.globalFilters });
        },
        addSuppliersFilter(state) {
            // push new empty filter to suppliersFilters
            Vue.set(state.suppliersFilters, state.suppliersFilters.length, createEmptyFilter());
        },

        suppliersFilterKeySetter(state, { index, filterId, key }) {
            filterKeySetter({
                index,
                filterId,
                key,
                filters: state.suppliersFilters,
                filterDefinitions: state.suppliersFilterDefinitions,
            });
        },

        suppliersFilterValueSetter(state, { index, filterId, value }) {
            filterValueSetter({ index, filterId, value, filters: state.suppliersFilters });
        },

        suppliersFilterDefinitionsSetter(state, { filterDefinitions }) {
            state.suppliersFilterDefinitions = filterDefinitions;
        },

        suppliersFiltersSetter(state, { filters }) {
            state.suppliersFilters = filters;
        },

        resetSuppliersFilters(state) {
            state.suppliersFilters = [createEmptyFilter()];
        },
        removeSuppliersFilter(state, index) {
            Vue.delete(state.suppliersFilters, index);
        },

        resetState(state) {
            Object.assign(state, getInitialState());
        },
        setParkingLotFilters(state, parkingLotFilters) {
            state.parkingLotFilters.selections = parkingLotFilters;
        },
        setParkingLotFiltersField(state, { field, value }) {
            state.parkingLotFilters.selections[field] = value;
        },
        setParkingLotOptions(state, parkingLotFilters) {
            state.parkingLotFilters.options = parkingLotFilters;
        },
        setParkingLotOptionsField(state, { field, value }) {
            state.parkingLotFilters.options[field] = value;
        },
        setIsNavigating(state, isNavigating) {
            state.isNavigating = isNavigating;
        },
    },

    // we've agreed that unless there's a strong case for it, there should be no other actions here
    actions: {
        // this is where you can preemptively fetch data before the page loads
        initialiseStateBackground({ dispatch }) {
            // dispatch any actions here
            dispatch('tagMetadata/fetchTagMetadata');
            dispatch('notifications/openNotificationStream');
            dispatch('freeGifts/fetchFreeGifts', {
                params: {
                    where: {
                        isActive: true,
                    },
                },
            });
            dispatch('loyaltyPoints/fetchLoyaltyPoints', {
                params: {
                    where: {
                        isActive: true,
                    },
                },
            });
            dispatch('notifications/fetchOpenNotifications');
            dispatch('userProfiles/fetchUserNames');
            return dispatch;
        },
        // this is where you can preemptively fetch data before the page loads
        initialiseStateBackgroundPostAppCreation({ dispatch }) {
            // dispatch any actions here that need to be done post context loading
            dispatch('promotions/fetchWeeklyMetrics');
            return dispatch;
        },

        // this is where we can fetch data that is required on app load
        async initialiseStateBlocking({ commit, dispatch, rootGetters }) {
            await Promise.all([
                dispatch('clientConfig/loadClientConfig', null, { root: true }),
                dispatch('fetchCommonData'),
            ]);

            // initialize state after translations are loaded
            commit('resetState');
            await dispatch('promotionCandidates/resetState', null, { root: true });

            // initialize filters
            const userCategoryKeys = rootGetters['context/userCategories'].map(
                category => category.levelEntryKey
            );
            if (userCategoryKeys.length === 1) {
                // set first filter, should only have 1 empty filter for both states at this point. see getInitialState.
                await dispatch('globalFilterKeySetter', {
                    index: 0,
                    filterId: 0,
                    key: filterEnums.categories,
                });
                // globalFilters is multiselect so entire array is value.
                await dispatch('globalFilterValueSetter', {
                    index: 0,
                    filterId: 0,
                    value: userCategoryKeys,
                });
                await dispatch('suppliersFilterKeySetter', {
                    index: 0,
                    filterId: 0,
                    key: filterEnums.categories,
                });
                // supplierFilters is single select so category levelEntryKey is value.
                await dispatch('suppliersFilterValueSetter', {
                    index: 0,
                    filterId: 0,
                    value: userCategoryKeys[0],
                });
            }
        },
        async fetchCommonData({ dispatch, rootState, rootGetters }) {
            // Retrieve data that is needed across pages in the tool.
            let dateParams = {};

            const plannerFirstDay = rootState.selectedDates.plannerFirstDay;
            const plannerLastDay = rootState.selectedDates.plannerLastDay;

            if (plannerFirstDay && plannerLastDay) {
                dateParams = {
                    where: {
                        endDate: {
                            $gte: plannerFirstDay,
                        },
                        startDate: {
                            $lte: plannerLastDay,
                        },
                    },
                };
            }

            const params = {
                ...dateParams,
                sortBy: 'startDate',
                sortDirection: 'ascending',
                // Only the subset of fields required to support the weekly view are retrieved initially for performance reasons.
                pick: [
                    '_id',
                    'name',
                    'startDate',
                    'endDate',
                    'briefing',
                    'isPrivate',
                    'campaignId',
                    'categories',
                    'tags',
                    'children',
                    'parentId',
                    'storeGroups',
                ].map(field => `{ "${field}": 1}`),
            };

            const selectedSubCampaign = rootGetters['subCampaigns/selectedSubCampaign'];

            await Promise.all([
                dispatch(
                    'campaigns/fetchCampaigns',
                    {
                        params,
                    },
                    { root: true }
                ),
                dispatch('subCampaigns/fetchSubCampaigns', {
                    params: {
                        ...params,
                    },
                }),
                dispatch('storeGroups/fetchStoreGroups'),
                dispatch('hierarchy/fetchHierarchy'),
                dispatch('attributeMetadata/fetchAttributeMetadata'),
                dispatch('weeks/fetchWeeks'),
                dispatch('offerMechanicPresets/fetchOfferMechanicPresets'),
            ]);

            // When there is a selected sub-campaign, make sure we've retrieved the full
            // set of fields for that sub-campaign and its campaign.
            if (selectedSubCampaign) {
                await Promise.all([
                    dispatch('campaigns/fetchCampaigns', {
                        params: {
                            where: {
                                _id: selectedSubCampaign.campaignId,
                            },
                        },
                        patchState: true,
                    }),
                    dispatch('subCampaigns/fetchSubCampaigns', {
                        params: {
                            where: {
                                campaignId: selectedSubCampaign.campaignId,
                            },
                        },
                        patchState: true,
                    }),
                ]);
            }

            // Once attribute metadata has been retrieved we should create the filter options in the store
            await dispatch('promotionCandidates/createAttributeMetadataFilters');
        },

        resetGlobalFilters({ commit, dispatch, state }, { filterId }) {
            dispatch('removeModuleFilter', {
                filterId,
                filters: state.globalFilters,
                filterDefinitions: state.globalFilterDefinitions,
            });
            commit('resetGlobalFilters');
        },

        removeGlobalFilter({ commit, dispatch, state }, { index, filterId }) {
            dispatch('removeModuleFilter', {
                filterId,
                filters: state.globalFilters,
                filterDefinitions: state.globalFilterDefinitions,
            });
            commit('removeGlobalFilter', index);
        },

        addGlobalFilter({ commit }) {
            commit('addGlobalFilter');
        },

        globalFilterKeySetter({ commit }, { index, filterId, key }) {
            commit('globalFilterKeySetter', { index, filterId, key });
        },

        globalFilterValueSetter({ commit, dispatch, state }, { index, filterId, value }) {
            commit('globalFilterValueSetter', { index, filterId, value });
            return dispatch('moduleFilterSetter', {
                index,
                value,
                filters: state.globalFilters,
                filterDefinitions: state.globalFilterDefinitions,
            });
        },

        addSuppliersFilter({ commit }) {
            commit('addSuppliersFilter');
        },

        suppliersFilterKeySetter({ commit }, { index, filterId, key }) {
            commit('suppliersFilterKeySetter', { index, filterId, key });
        },

        suppliersFilterValueSetter({ commit, dispatch, state }, { index, filterId, value }) {
            commit('suppliersFilterValueSetter', { index, filterId, value });
            return dispatch('moduleFilterSetter', {
                index,
                value,
                filters: state.suppliersFilters,
                filterDefinitions: state.suppliersFilterDefinitions,
            });
        },

        suppliersFilterDefinitionsSetter({ commit }, { filterDefinitions }) {
            commit('suppliersFilterDefinitionsSetter', { filterDefinitions });
        },

        suppliersFiltersSetter({ commit }, { filters }) {
            commit('suppliersFiltersSetter', { filters });
        },

        moduleFilterSetter({ dispatch, rootState }, { index, value, filters, filterDefinitions }) {
            const filterKey = filters[index].filterKey;
            const filterDefinition = find(filterDefinitions, { filterKey });
            const updatedFilter =
                isArray(value) && isEmpty(value)
                    ? omit(rootState[filterDefinition.module].filter, filterKey)
                    : { ...rootState[filterDefinition.module].filter, [filterKey]: value };
            return dispatch(`${filterDefinition.module}/setSelectedFilter`, updatedFilter);
        },

        resetSuppliersFilters({ commit, dispatch, state }, { filterId }) {
            dispatch('removeModuleFilter', {
                filterId,
                filters: state.suppliersFilters,
                filterDefinitions: state.suppliersFilterDefinitions,
            });
            commit('resetSuppliersFilters');
        },

        removeSuppliersFilter({ commit, dispatch, state }, { index, filterId }) {
            dispatch('removeModuleFilter', {
                filterId,
                filters: state.suppliersFilters,
                filterDefinitions: state.suppliersFilterDefinitions,
            });
            commit('removeSuppliersFilter', index);
        },

        removeModuleFilter({ dispatch, rootState }, { filterId, filters, filterDefinitions }) {
            const filterKey = get(find(filters, { filterId }), 'filterKey', null);
            if (filterKey) {
                const filterDefinition = find(filterDefinitions, { filterKey });
                const updatedFilter = omit(rootState[filterDefinition.module].filter, filterKey);
                return dispatch(`${filterDefinition.module}/setSelectedFilter`, updatedFilter);
            }
        },

        initializeSuppliersFilter({ dispatch, commit, rootState, state }) {
            commit('resetSuppliersFilters');
            forEach(state.suppliersFilterDefinitions, ({ module, filterKey }, i) => {
                const filterValue = rootState[module].filter[filterKey];
                if (filterValue) {
                    if (i !== 0) {
                        dispatch('addSuppliersFilter');
                    }
                    commit('suppliersFilterKeySetter', { index: i, key: filterKey });
                    commit('suppliersFilterValueSetter', { index: i, value: filterValue });
                }
            });
        },
    },

    modules: {
        context,
        workPackages,
        campaigns,
        subCampaigns,
        scenarios,
        notifications,
        promotions,
        promotionVersions,
        products,
        promotionProductGroups,
        selectedDates,
        storeGroups,
        promotionCandidates,
        hierarchy,
        clientConfig,
        productPrices,
        productCosts,
        weeks,
        forecasting,
        attributeMetadata,
        nominationTemplates,
        supplierCommitments,
        suppliers,
        rateCards,
        variableFundingAgreements,
        tagMetadata,
        freeGifts,
        reporting,
        bookmarks,
        execution,
        loyaltyPoints,
        userProfiles,
        targets,
        redisBull,
        offerMechanicPresets,
    },

    // plugins: [vuexLocalStorage.plugin, createLogger()],
    plugins: [vuexLocalStorage.plugin],
});

export default store;
