<template>
    <div class="rtls-select-container" :class="cssClass">
        <p v-if="label" class="rtls-select-container__label">
            {{ $t(label) | toSentenceCase }}
        </p>
        <v-autocomplete
            ref="select"
            v-model="model"
            class="rtls-select"
            :class="{
                'rtls-select--auto-height': (chips || multiple) && !horizontal,
                'rtls-select--white': !filled,
            }"
            :items="getOptions"
            :placeholder="$t(placeholder || 'placeholders.select') | toSentenceCase"
            :menu-props="{ bottom: true, offsetY: true, openOnClick: false, ...maxHeight }"
            :disabled="isDisabled"
            :multiple="multiple"
            :item-text="itemText"
            :item-value="itemValue"
            :small-chips="chips"
            :deletable-chips="deletableChips"
            single-line
            :rules="rules"
            :validate-on-blur="validateOnBlur"
            :clearable="showClearButton"
            :return-object="returnObject"
            :search-input.sync="searchInput"
            @input="searchInput = null"
            @change="change"
            @click:clear="clear"
            @click="handleClick"
            @blur="cleanNewlyChangedItems()"
            @keypress.enter.prevent
        >
            <template slot="append">
                <icon-button :icon="appendIcon" size="28" @click.prevent="handleClick" />
            </template>

            <template v-if="multiple && hasSelectAll" v-slot:prepend-item>
                <v-list-item ripple @click="toggle">
                    <v-list-item-action>
                        <v-icon :color="iconColor">{{ icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ selectText | toSentenceCase }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2" />
            </template>

            <template v-if="chips" v-slot:selection="{ parent, item, index }">
                <v-tooltip
                    v-if="maxItemsToDisplaySelected || index < maxItemsInList - 1 || !finiteList"
                    right
                    content-class="rtls-tooltip"
                    :disabled="!item.invalid"
                >
                    <template v-slot:activator="{ on }">
                        <v-chip
                            class="v-chip--select"
                            small
                            :close="deletableChips && !isDisabled && !itemDisabled(item)"
                            :disabled="itemDisabled(item)"
                            v-bind="item.invalid ? { color: 'red', outlined: true } : {}"
                            v-on="on"
                            @click:close="() => parent.onChipInput(item)"
                        >
                            <v-tooltip :disabled="hideTooltip" top>
                                <template v-slot:activator="{ on: criteria, attrs }">
                                    <span
                                        ref="truncated"
                                        v-bind="attrs"
                                        class="truncate-text"
                                        v-on="criteria"
                                        @mouseover="checkTooltipRequired"
                                    >
                                        {{ getItemString(item) }}
                                    </span>
                                </template>
                                <span>{{ getItemString(item) }}</span>
                            </v-tooltip>
                        </v-chip>
                    </template>
                    {{ $tkey(invalidSelectionText) | toSentenceCase }}
                </v-tooltip>
                <span
                    v-if="!maxItemsToDisplaySelected && index === maxItemsInList - 1 && finiteList"
                >
                    <v-chip class="v-chip--select pr-2" small>
                        <span class="truncate-text">
                            {{ $t('planning.createCampaign.xMore', { number: xOthers }) }}
                            <v-menu bottom offset-y open-on-hover>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-if="!isDisabled"
                                        id="mdi"
                                        size="20"
                                        class="pl-1"
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                        >mdi-dots-horizontal-circle</v-icon
                                    >
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-for="(othersValue, othersIndex) in xOthersArray"
                                        :key="othersIndex"
                                        :disabled="itemDisabled(item)"
                                    >
                                        <v-list-item-title> {{ othersValue }}</v-list-item-title>
                                        <span v-if="deletableChips">
                                            <v-icon
                                                :disabled="itemDisabled(item)"
                                                :color="iconColor"
                                                @click="deselectOne(othersValue)"
                                            >
                                                mdi-close-circle
                                            </v-icon>
                                        </span>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </span>
                    </v-chip>
                </span>
            </template>

            <template v-else v-slot:selection="{ item }">
                <v-tooltip :disabled="hideTooltip" top>
                    {{ getItemString(item) }}
                    <template v-slot:activator="{ on: criteria, attrs }">
                        <span
                            ref="truncated"
                            v-bind="attrs"
                            class="truncate-text"
                            v-on="criteria"
                            @mouseover="checkTooltipRequired"
                        >
                            {{ getItemString(item) }}
                        </span>
                    </template>
                    <span>{{ getItemString(item) }}</span>
                </v-tooltip>
            </template>

            <template v-slot:item="{ item, attrs, on }">
                <v-list-item
                    #default="{ active }"
                    dense
                    :disabled="itemDisabled(item)"
                    v-bind="attrs"
                    :class="itemColourClass(item)"
                    v-on="on"
                    @change="changeOne(item)"
                >
                    <v-list-item-action v-if="multiple">
                        <v-checkbox :input-value="active" />
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-row no-gutters>
                                <span>{{ text(item) }}</span>
                                <v-spacer />
                            </v-row>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
import {
    sortBy,
    get,
    intersectionBy,
    intersection,
    size,
    isObject,
    isFunction,
    lowerCase,
    map,
    findIndex,
    isArray,
} from 'lodash';
import vuexComponentMixin from '@/js/mixins/vuex-component';

const sortByItemText = function(options) {
    return sortBy(options, [i => lowerCase(i[this.itemText])]);
};

export default {
    mixins: [vuexComponentMixin],

    props: {
        multiple: Boolean,
        options: [Array, Object],
        itemValue: {
            type: [String, Function],
            default: 'value',
        },
        itemText: {
            type: [String, Function],
            default: 'text',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        checkDisabled: {
            type: Function,
            default: () => false,
        },
        placeholder: {
            type: String,
        },
        label: String,
        cssClass: String,
        chips: Boolean,
        horizontal: Boolean,
        deletableChips: Boolean,
        filled: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        sortFunction: {
            type: Function,
            default: sortByItemText,
        },
        sortResults: {
            type: Boolean,
            default: true,
        },
        invalidSelectionText: {
            type: String,
            default: 'general.info.invalidSelection',
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        validateOnBlur: {
            type: Boolean,
            default: true,
        },
        finiteList: {
            type: Boolean,
            default: false,
        },
        maxItemsInList: {
            type: Number,
            default: 3,
        },
        hasSelectAll: {
            type: Boolean,
            default: true,
        },
        hideDropdown: {
            type: Boolean,
            default: false,
        },
        appendIcon: {
            type: String,
            default: 'expand_more',
        },
        changeHandler: {
            type: Function,
            required: false,
        },
    },

    data() {
        return {
            searchInput: null,
            hideTooltip: null,
            newlyChanged: new Set(),
        };
    },

    computed: {
        getOptions() {
            const options = this.parsedField ? this.parsedField.options : this.options;
            return this.sortResults ? this.sortFunction(options) : options;
        },
        selectAll() {
            return this.model && this.model.length === this.getOptions.length;
        },
        selectSome() {
            return this.model && this.model.length > 0 && !this.selectAll;
        },
        icon() {
            if (this.selectAll) return 'mdi-close-box';
            if (this.selectSome) return 'mdi-minus-box';
            return 'mdi-checkbox-blank-outline';
        },
        iconColor() {
            return this.model && this.model.length > 0 ? 'primary' : '';
        },
        isDisabled() {
            return this.checkDisabled() || this.disabled || this.isReadOnly;
        },
        selectText() {
            return this.selectAll ? this.$t('actions.deselectAll') : this.$t('actions.selectAll');
        },
        xOthers() {
            return size(this.model) - (this.maxItemsInList - 1);
        },
        xOthersArray() {
            const modelOptionsNotOnDisplayText = this.model
                .slice(this.maxItemsInList - 1)
                .map(modelItem => {
                    if (isFunction(this.itemText)) {
                        return this.itemText(modelItem);
                    }
                    if (isObject(modelItem)) {
                        return modelItem[this.itemText];
                    }
                    return this.getOptions.find(option => option[this.itemValue] === modelItem)[
                        this.itemText
                    ];
                });
            return modelOptionsNotOnDisplayText;
        },
        xOthersText() {
            return this.xOthersArray.join(', ');
        },
        disabledOptions() {
            return this.getOptions.filter(option => get(option, 'disabled', false));
        },
        showClearButton() {
            // We need to hide clear button when clearing deselects something user doesn't have access to.
            if (this.clearable) {
                // For the multiple case it is handled elsewise.
                if (this.multiple) return true;
                // Check if selected option is disabled
                // If returnObject === true we simply check model.
                if (this.returnObject) {
                    return !get(this.model, 'disabled', false);
                }
                // Else we find selected in options
                const itemValueIsFunction = isFunction(this.itemValue);
                const selectedOption = this.getOptions.find(option => {
                    if (itemValueIsFunction) {
                        return this.itemValue(option) === this.itemValue(this.model);
                    }
                    return option[this.itemValue] === this.model;
                });
                return !get(selectedOption, 'disabled', false);
            }
            return false;
        },
        maxItemsToDisplaySelected() {
            return size(this.model) === this.maxItemsInList;
        },
        maxHeight() {
            if (this.hideDropdown) {
                return { maxHeight: 0 };
            }
            return {};
        },
    },

    methods: {
        getItemString(item) {
            return isObject(item) ? item[this.itemText] : item;
        },
        handleClick(event) {
            if (this.hideDropdown) {
                event.stopPropagation();
            }
            this.$emit('clicked');
        },
        checkTooltipRequired(event) {
            // scrollWidth includes content not visible due to overflow, whilst offsetWidth includes only visible content.
            // If these differ, then a tooltip is required to display the full text.
            const { offsetWidth, scrollWidth } = event.target;
            this.hideTooltip = offsetWidth === scrollWidth;
        },

        deselectOne(value) {
            const id = this.getOptions.find(option => option.text === value).value;
            this.model = this.model.filter(model => model !== id);
        },

        toggle() {
            // Replaced directly changing model with calling setValue method from v-select
            // It helps with validation issue
            // when we do this.model = [] validation state change only in next tick and we send wrong request
            // or not sending correct request in case if user click 'select all' after clicking 'deselect all'
            // this.$emit('change', value) calling as part of setValue();
            this.$nextTick(() => {
                if (this.selectAll) {
                    // deselect all
                    this.deselectAll(this.model);
                } else {
                    // select all

                    const disabledUnselectedOptionsValues = this.disabledOptions
                        // check if disabled option is unselected, i.e. option is not included in model
                        .filter(
                            option =>
                                findIndex(this.model, item => {
                                    const itemValue = this.returnObject
                                        ? item[this.itemValue]
                                        : item;
                                    return option[this.itemValue] === itemValue;
                                }) === -1
                        )
                        // create array of unselected disabled option values
                        .map(option => option[this.itemValue]);

                    // disabled and unselected options can't be selected by 'Select All' button
                    const optionsToSelect = this.getOptions.filter(
                        option =>
                            findIndex(
                                disabledUnselectedOptionsValues,
                                key => key === option[this.itemValue]
                            ) === -1
                    );

                    this.$refs.select.setValue(
                        optionsToSelect.map(option =>
                            this.returnObject ? option : option[this.itemValue]
                        )
                    );
                }
            });
        },

        change(value) {
            this.$emit('change', value);
            if (this.changeHandler) {
                this.changeHandler(value);
            }
        },

        changeOne(item) {
            const value = item.value;
            this.newlyChanged.add(value);
        },

        cleanNewlyChangedItems() {
            this.newlyChanged = new Set();
        },

        reset() {
            // nextTick needed otherwise the model doesn't get updated
            this.$nextTick(() => {
                this.model = null;
            });
        },

        itemDisabled(item) {
            return get(item, 'disabled', false);
        },

        itemColourClass(item) {
            if (!this.model) return 'unselected';
            if (this.newlyChanged.has(item.value)) return 'selected-black';
            if (isArray(this.model)) {
                return this.model.includes(item.value) ? 'selected-grey' : 'unselected';
            }
            return this.model === item.value ? 'selected-grey' : 'unselected';
        },

        text(item) {
            if (typeof this.itemText === 'function') return this.itemText(item);
            return get(item, [this.itemText], item);
        },

        clear() {
            if (this.multiple) {
                const originalModel = this.model.slice();
                this.$nextTick(() => {
                    this.deselectAll(originalModel);
                });
            }
        },

        deselectAll(originalModel) {
            const disabledModel = this.returnObject
                ? intersectionBy(originalModel, this.disabledOptions, this.itemValue)
                : intersection(originalModel, map(this.disabledOptions, this.itemValue));
            // disabled options can't be deselected by "Deselect All" or "clear" buttons
            this.$refs.select.setValue(disabledModel);
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
.rtls-select-container {
    .rtls-select {
        &::v-deep {
            .v-input__control .v-input__slot .v-select__slot input {
                padding-left: 0.5rem;
                &::placeholder {
                    color: $promo-grey-dark;
                }
            }

            .v-input__control
                .v-input__slot
                .v-select__slot
                .v-select__selections
                span.truncate-text {
                padding-left: 0.5rem;
            }
        }
    }

    .v-input--is-disabled .theme--light.v-icon {
        opacity: 0.3;
    }
}
.v-menu__content {
    border-radius: 0 !important;
}

.mdi-close-circle {
    margin-left: 1rem;
    &:hover {
        color: $promo-black !important;
    }
}

#mdi:hover {
    color: $rtls-secondary-button-hover-colour !important;
}

.v-list-item--disabled > .v-list-item__content {
    color: $promo-grey-7 !important;
}

.v-list-item__title {
    font-size: 1.2rem;
}
</style>
