import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main-dialog", {
    ref: "dialog",
    attrs: {
      "translated-header": _vm.$t("dialogs.bulkDecline.declineHeader"),
      "btn-confirm-text": _vm.$t("actions.ok"),
      "btn-cancel-text": _vm.actionsCancelText,
      "has-activator": true,
      "confirm-btn-disabled": _vm.confirmButtonDisabled,
    },
    on: {
      confirm: _vm.confirm,
      open: function ($event) {
        return _vm.$emit("open")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "actions",
        fn: function ({}) {
          return [
            _c(
              VBtn,
              {
                attrs: { text: "", disabled: _vm.isDisabled },
                on: { click: _vm.onClick },
              },
              [
                _vm.isDisabled
                  ? _c("div", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$t("dialogs.bulkDecline.noPromotions")
                            )
                          ) +
                          "\n            "
                      ),
                    ])
                  : _c("div", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$t("dialogs.bulkDecline.declinButtonTitle", {
                                number: _vm.promotionIds.length,
                              })
                            )
                          ) +
                          "\n            "
                      ),
                    ]),
              ]
            ),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c("div", { staticClass: "decline-promotions" }, [
              !_vm.confirmButtonDisabled
                ? _c("h2", [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("toSentenceCase")(
                            _vm.$t("dialogs.bulkDecline.declineTitle", {
                              number: _vm.promotionIds.length,
                            })
                          )
                        ) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.successDeclinedPromotions.length
                ? _c(
                    "div",
                    { staticClass: "decline-promotions__result" },
                    [
                      _c("icon", { attrs: { "icon-name": "success-circle" } }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("toSentenceCase")(
                                _vm.$t(
                                  "dialogs.bulkDecline.successDeclineMessage",
                                  {
                                    number:
                                      _vm.successDeclinedPromotions.length,
                                  }
                                )
                              )
                            ) +
                            "\n                "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.failedDeclinedPromotions.length
                ? _c(
                    "div",
                    { staticClass: "decline-promotions__result" },
                    [
                      _c("icon", { attrs: { "icon-name": "failure-circle" } }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("toSentenceCase")(
                                _vm.$t(
                                  "dialogs.bulkDecline.failedDeclineMessage",
                                  {
                                    number: _vm.failedDeclinedPromotions.length,
                                  }
                                )
                              )
                            ) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        _vm._l(_vm.failedDeclinedPromotions, function (promo) {
                          return _c("li", { key: `failed::${promo._id}` }, [
                            _c("span", [_vm._v(_vm._s(promo.name))]),
                          ])
                        }),
                        0
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }