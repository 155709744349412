<template>
    <main-dialog
        ref="dialog"
        :translated-header="$t('dialogs.bulkDecline.declineHeader')"
        :btn-confirm-text="$t('actions.ok')"
        :btn-cancel-text="actionsCancelText"
        :has-activator="true"
        :confirm-btn-disabled="confirmButtonDisabled"
        @confirm="confirm"
        @open="$emit('open')"
    >
        <template v-slot:actions="{}">
            <v-btn text :disabled="isDisabled" @click="onClick">
                <div v-if="isDisabled">
                    {{ $t('dialogs.bulkDecline.noPromotions') | toSentenceCase }}
                </div>
                <div v-else>
                    {{
                        $t('dialogs.bulkDecline.declinButtonTitle', {
                            number: promotionIds.length,
                        }) | toSentenceCase
                    }}
                </div>
            </v-btn>
        </template>
        <template v-slot:default>
            <div class="decline-promotions">
                <h2 v-if="!confirmButtonDisabled">
                    {{
                        $t('dialogs.bulkDecline.declineTitle', {
                            number: promotionIds.length,
                        }) | toSentenceCase
                    }}
                </h2>
                <div v-if="successDeclinedPromotions.length" class="decline-promotions__result">
                    <icon icon-name="success-circle" />
                    <span>
                        {{
                            $t('dialogs.bulkDecline.successDeclineMessage', {
                                number: successDeclinedPromotions.length,
                            }) | toSentenceCase
                        }}
                    </span>
                </div>
                <div v-if="failedDeclinedPromotions.length" class="decline-promotions__result">
                    <icon icon-name="failure-circle" />
                    <span>
                        {{
                            $t('dialogs.bulkDecline.failedDeclineMessage', {
                                number: failedDeclinedPromotions.length,
                            }) | toSentenceCase
                        }}
                    </span>
                    <ul>
                        <li v-for="promo in failedDeclinedPromotions" :key="`failed::${promo._id}`">
                            <span>{{ promo.name }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </main-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        promotionIds: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            successDeclinedPromotions: [],
            failedDeclinedPromotions: [],
        };
    },
    computed: {
        isDisabled() {
            return !this.promotionIds.length;
        },
        confirmButtonDisabled() {
            return !!(
                this.successDeclinedPromotions.length || this.failedDeclinedPromotions.length
            );
        },
        actionsCancelText() {
            return this.confirmButtonDisabled ? 'actions.close' : 'actions.cancel';
        },
    },
    methods: {
        ...mapActions('promotions', ['bulkDeclinePromotions']),
        async confirm() {
            const result = await this.bulkDeclinePromotions({ promotionIds: this.promotionIds });
            this.successDeclinedPromotions = result.successDeclinedPromotions;
            this.failedDeclinedPromotions = result.failedDeclinedPromotions;
            if (this.successDeclinedPromotions.length) {
                this.$emit('decline', this.successDeclinedPromotions);
            }
        },
        onClick() {
            this.successDeclinedPromotions = [];
            this.failedDeclinedPromotions = [];
            this.$refs.dialog.open();
        },
    },
};
</script>

<style lang="scss" scoped>
.decline-promotions {
    min-height: 10rem;
    width: 100%;

    &__result {
        margin: 2rem 0;
        font-size: 1.5rem;
    }
}
</style>
